<template>
    <div class="module module-image" :class="{'image-wide': value.image_width === 'wide'}">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a>
        <div class="grid-container grid-x">
            <div class="cell small-12 medium-10 large-8" :class="{'medium-12 large-10': value.image_width === 'wide'}">
                <PictureRichSnippet v-if="$parent.$parent.$parent.$parent.lazyload.includes($parent.pageid)" :res="$parent.$parent.$parent.$parent.responsive_size" :image="value.image" :alt="value.image.alt" />
            </div>
            <div class="cell small-12 medium-10 large-8 special-figcaption">
                <div v-if="text" v-on:click="click($event)" v-html="text" />
            </div>
        </div>

    </div>
</template>

<script>
import processAnchors from '../../scripts/replaceInternalAnchors'
    import PictureRichSnippet from '../snippets/PictureRichSnippet'

    export default {
        props: {
            value: {
                type: Object
            }
        },
        components: {
            PictureRichSnippet
        },
        data: function () {
            return{
                text: ""
            }
        },
        methods: {
            click(event){
                let target = event.target;
                if(target.id) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.$parent.$emit('goTo', {id: target.id, mode: "link", customHash: target.attributes.slug ? target.attributes.slug.value : false})
                }
            }
        },
        created(){
            // Replace all anchors with a page id so they have href and a link can be shared
            this.text = processAnchors(this.value.image_caption, this.$parent.data)
        }
    }
</script>