<template>
    <div class="module module-accordion">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a>                
        <div class="grid-container grid-x">
            <ul class="accordion cell small-12 medium-10 large-8" data-accordion data-allow-all-closed="true" >
                <li v-for="accordion in value.content" v-bind:key="accordion.id" class="accordion-item" data-accordion-item :class="accordion.type">
                    <a class="accordion-title h4" :class="{'open': accordion.id === active}" :id="accordion.id" v-on:click="click($event.target, accordion.id, accordion.value.accordion_slug)">
                        <span>{{ accordion.value.title }}</span>
                        <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.6152 1.51562L10.9998 11.131L1.38446 1.51562" stroke="#2C2C2C" stroke-width="2"/>
                        </svg>
                    </a>
                    <div class="accordion-content" data-tab-content :class="[{'open': accordion.id === active, 'grid-x grid-margin-x grid-margin-y':accordion.type!='accordion_text_item'}]">
                        <template v-if="accordion.type!='accordion_text_item'">              
                            <Download v-for="block in accordion.value.content" v-bind:key="block.id" :block="block" />
                        </template>              
                        <template v-if="accordion.type=='accordion_text_item'">              
                            <LeanTextBlock :value="accordion.value" v-bind:key="accordion.id" />
                        </template>              
                    </div>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
    import Download from './Download'
    import LeanTextBlock from './LeanTextBlock'

    export default {
        props: {
            value: {
                type: Object
            },
            hash: {
                type: String
            },
            reset: {
                type: Boolean
            }
        },
        data: function (){
            return{
                active: "",
            }
        },
		components: {
			Download,
			LeanTextBlock,
        },
        methods:{
            click(target, id, slug){
                if(id){
                    let page = this.$parent.$parent.$parent.$parent.json_data.find(o => o.id === this.$parent.$parent.$parent.$parent.active_id);
                    if(this.active === id){
                        history.pushState({page: page.id}, page.title, `${page.url}`)
                    }else{
                        history.pushState({page: page.id}, page.title, `${page.url}${"#"+slug}`)
                    }
                }

                if(this.active === target.id) this.active = 0;
                else this.active = target.id;
			}
        },
		watch: { 
			// Open accordion by link slug
			$props: {
				handler: function() {
                    if(this.reset){
                        let activate = this.value.content.find(o => o.value.accordion_slug === this.hash);
                        if(activate) this.active = activate.id;
                        else this.active = 0;
                        this.$parent.$parent.$parent.$parent.hash = "";
                        this.$parent.$parent.$parent.$parent.resetAccordion = false;
                    }
				},
				immediate: true,
				deep: true
			},
		}
    }
</script>