<template>
	<div class="page downloads-page">
		
		<div class="page-content">

			<div v-if="page.lead_text" class="grid-container grid-x lead-text" :class="this.$root.$children[0].$children[0].active_id === this.page.id && 'h-title'">
				<div class="cell small-12 medium-10 large-8" v-html="page.lead_text" />
			</div>
			<Blocks :pageid="page.id" :body="page.body" :data="$parent.$parent.json_data" @goTo="$parent.goTo" />
		</div>

	</div>
</template>

<script>
	import Blocks from '../Blocks'

	export default {
		props: {
			page: {
				type: Object
			},
		},
		data: function () {
			return { 
				pgettext: window.pgettext,
				mediaURLPrefix: window.mediaURLPrefix,
			}
		},
		components: {
			Blocks,
		},
		methods: {
			getUrl(id) {
				let page = this.$parent.$parent.json_data.find(o => o.id === id);
				return page.url;
			}
		},
		mounted() {
			if (this.$root.$children[0].$children[0].active_id === this.page.id) 
				this.$root.$children[0].$children[0].updateScrollPosition(this.$el)

		}
	}
</script>

