<template>
	<form @submit.prevent class="search-form">
        <input v-model="search" type="text" ref="focusSearchField" value="" class="search-input" :placeholder="placeholder"/>
        <button type="submit" value="Search" class="search-button">
            <svg v-if="!loading" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5936 23.4375C19.0302 23.4375 23.4374 19.0303 23.4374 13.5938C23.4374 8.1572 19.0302 3.75 13.5936 3.75C8.15707 3.75 3.74988 8.1572 3.74988 13.5938C3.74988 19.0303 8.15707 23.4375 13.5936 23.4375Z" stroke="#777777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.554 20.5547L26.2493 26.2501" stroke="#777777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 40 40">
                <path opacity="0.2" fill="#777777" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"></path>
                <path fill="#777777" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" transform="rotate(323.685 20 20)">
                <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite"></animateTransform>
                </path>
            </svg>
        </button>
    </form>
</template>

<script>
    import debounce from "lodash.debounce"
    export default {
		props: {
            placeholder: {
                type: String
            },
            loading: {
                type: Boolean,
                default: false
            },
            clearSearchField: {
                type: Boolean,
            },
            focusSearchField: {
                type: Boolean,
            }
        },
		components: {},
        watch: {
            search(...args) {
                this.debouncedWatch(...args);
            },
            clearSearchField: function(newVal, oldVal) { // watch it
                if (oldVal==false && newVal==true) {
                    // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                    this.search=''
                }
            },
            focusSearchField: function(newVal, oldVal) { // watch it
                if (oldVal==false && newVal==true) {
                    // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                    this.$refs.focusSearchField.focus()
                }
            },
        },
		data: function () {
			return {
                search: ''
            }
		},
		methods: {

        },
        created() {
            this.debouncedWatch = debounce((newValue, oldValue) => {
                if(this.search.length > 2 || newValue == '') {
                    this.$emit('fire-search', newValue)
                } else {
                    this.$emit('clean-search')
                }
            }, 500)
        },

        beforeUnmount() {
            this.debouncedWatch.cancel();
        }
	}
</script>