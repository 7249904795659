<template>
	<ul class="links-sharing">
		<li v-if="links.facebook">
			<a target="_blank"
				:title="typeof pgettext === 'function' && pgettext ? pgettext('Social Links - Title for button to visit Facebook page', 'Visit BetonSuisse on Facebook') : 'Visit BetonSuisse on Facebook'"
				:href="links.facebook">
				<svg width="10" height="21" viewBox="0 0 10 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M2.13555 10.3678V20.3144C2.13555 20.459 2.24872 20.5721 2.39333 20.5721H6.084C6.22861 20.5721 6.34178 20.459 6.34178 20.3144V10.2043H9.02018C9.15221 10.2043 9.26539 10.1037 9.27796 9.96542L9.53574 6.92235C9.54832 6.77146 9.42886 6.63942 9.27796 6.63942H6.34178V4.48287C6.34178 3.97359 6.75045 3.56492 7.25973 3.56492H9.32197C9.46658 3.56492 9.57975 3.45174 9.57975 3.30714V0.257781C9.58604 0.113172 9.47287 0 9.32826 0H5.83879C3.79541 0 2.13555 1.65986 2.13555 3.70324V6.63942H0.287077C0.142469 6.63942 0.0292969 6.75888 0.0292969 6.8972V9.94027C0.0292969 10.0849 0.142469 10.1981 0.287077 10.1981H2.13555V10.3678Z" fill="black"/>
				</svg>
			</a>
		</li>
		<li v-if="links.instagram">
			<a target="_blank"
				:title="typeof pgettext === 'function' && pgettext ? pgettext('Social Links - Title for button to visit Instagram page', 'Visit BetonSuisse on Instagram') : 'Visit BetonSuisse on Instagram'"
				:href="links.instagram">
				<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M16.024 4.7628C15.3441 4.7628 14.7873 5.31373 14.7873 5.99947C14.7873 6.67935 15.3383 7.23614 16.024 7.23614C16.7039 7.23614 17.2607 6.68521 17.2607 5.99947C17.2607 5.31373 16.7039 4.7628 16.024 4.7628ZM10.5322 6.34527C7.67206 6.34527 5.34524 8.67209 5.34524 11.5323C5.34524 14.3924 7.67206 16.7193 10.5322 16.7193C13.3924 16.7193 15.7192 14.3924 15.7192 11.5323C15.7192 8.67209 13.3924 6.34527 10.5322 6.34527ZM10.5322 14.8496C8.69774 14.8496 7.20904 13.3609 7.20904 11.5264C7.20904 9.69191 8.69774 8.20321 10.5322 8.20321C12.3667 8.20321 13.8554 9.69191 13.8554 11.5264C13.8554 13.3609 12.3667 14.8496 10.5322 14.8496ZM21 7.21856C21 3.7254 18.1691 0.894531 14.676 0.894531H6.32403C2.83087 0.894531 0 3.7254 0 7.21856V15.5646C0 19.0637 2.83087 21.8945 6.32403 21.8945H14.6701C18.1633 21.8945 20.9941 19.0637 20.9941 15.5705V7.21856H21ZM19.019 15.5705C19.019 17.9677 17.0731 19.9135 14.676 19.9135H6.32403C3.92688 19.9135 1.98102 17.9677 1.98102 15.5705V7.21856C1.98102 4.82141 3.92688 2.87555 6.32403 2.87555H14.6701C17.0673 2.87555 19.0131 4.82141 19.0131 7.21856V15.5705H19.019Z" fill="#2C2C2C"/>
				</svg>
			</a>
		</li>
		<li v-if="links.linkedin">
			<a target="_blank"
				:title="typeof pgettext === 'function' && pgettext ? pgettext('Social Links - Title for button to visit linkedin page', 'Visit BetonSuisse on linkedin') : 'Visit BetonSuisse on linkedin'"
				:href="links.linkedin">
				<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.6225 11.128V17.8429C19.6225 18.0063 19.4905 18.1447 19.3207 18.1447H15.8564C15.693 18.1447 15.5546 18.0126 15.5546 17.8429V11.5996C15.5546 9.95856 14.9699 8.83313 13.4924 8.83313C12.367 8.83313 11.7005 9.58761 11.405 10.3232C11.2981 10.5873 11.273 10.9457 11.273 11.3166V17.8492C11.273 18.0126 11.1409 18.151 10.9712 18.151H7.50685C7.34338 18.151 7.20506 18.0189 7.20506 17.8492C7.21135 16.1893 7.24907 8.11638 7.21135 6.2176C7.20506 6.04784 7.34338 5.91581 7.51314 5.91581H10.9712C11.1346 5.91581 11.273 6.04784 11.273 6.2176V7.65111C11.2667 7.66369 11.2541 7.67626 11.2478 7.68884H11.273V7.65111C11.8137 6.82119 12.7756 5.63288 14.9385 5.63288C17.6169 5.62031 19.6225 7.36818 19.6225 11.128ZM1.19437 18.1447H4.65869C4.82216 18.1447 4.96048 18.0126 4.96048 17.8429V6.21131C4.96048 6.04784 4.82845 5.90952 4.65869 5.90952H1.19437C1.0309 5.90952 0.892578 6.04156 0.892578 6.21131V17.8429C0.892578 18.0063 1.0309 18.1447 1.19437 18.1447Z" fill="black"/>
					<path d="M4.98603 2.17542C4.98603 3.3763 4.01149 4.35083 2.81061 4.35083C1.60973 4.35083 0.628906 3.38258 0.628906 2.17542C0.628906 0.968249 1.60344 0 2.80432 0C4.0052 0 4.98603 0.974536 4.98603 2.17542Z" fill="black"/>
				</svg>
			</a>
		</li>
	</ul>
</template>

<script>
	export default {
		props: {
			links: {
				type: Object
			},
		},
		data: function () {
			return { 
				pgettext: window.pgettext,
			}
		}
	}
</script>