<template>
    <div class="page blog-index-page">
        <FeaturedPost :post="page.main_post" :parent="page"/>
        <div class="page-content">
            <div class="grid-container grid-x">
                <div class="cell small-12 medium-8 margin-auto blog-index-search">
                    <SearchForm
                        :placeholder="searchFieldLabel()"
                        :loading="loading"
                        @fire-search="fireSearch"
                        @clean-search="cleanSearch" />
                </div>

                <div v-if="apiResponse.items && apiResponse.items.length > 0" class="blog-posts-grid">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div v-for="record in apiResponse.items" :key="record.id"
                                class="cell small-12 medium-6 large-4 blogpost-link-section-cell link-block">
                            <PostCard :post="record" :res="$parent.$parent.responsive_size"/>
                        </div>
                    </div>
                </div>
                
                <div v-if="search !='' && apiResponse.items && apiResponse.items.length == 0" class="no-results-found-message cell small-12 medium-8 margin-auto no">
                    <p class="search-results-found">{{typeof pgettext === "function" && pgettext ? pgettext('Label of search results not found', 'No search results found') : "No search results found"}}</p>
                </div>                        

                <div v-if="loading || hasMorePosts" class="cell small-12 text-center load-more-wrapper">
                    <button :disabled="loading" v-on:click="morePosts()"
                            class="hollow button button-form button-white">
                        <span v-if="!loading">{{typeof pgettext === "function" && pgettext ? pgettext('Load more pagination button', 'load more') : "load more"}}</span>
                        <span v-if="loading">{{typeof pgettext === "function" && pgettext ? pgettext('Loading status on Load more button', 'loading') : "loading"}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FeaturedPost from '../snippets/FeaturedPost'
    import SearchForm from '../snippets/SearchForm'
    import PostCard from '../snippets/PostCard'
    import axios from "axios"

    export default {
        props: {
            page: {
                type: Object
            }
        },
        data: function () {
            return {
                mediaURLPrefix: window.mediaURLPrefix,
                search: '',
                pageNumber: 1,
                loading: false,
                apiResponse: {},
            }
        },
        components: {
            SearchForm,
            FeaturedPost,
            PostCard
        },
        mounted() {
            if (this.$root.$children[0].$children[0].active_id === this.page.id)
                this.$root.$children[0].$children[0].updateScrollPosition(this.$el)
        },
        computed: {
            hasMorePosts() {
                // eslint-disable-next-line no-prototype-builtins
                if (!this.apiResponse.hasOwnProperty('meta')) {
                    return false
                }
                let total = this.apiResponse.meta.total_count
                return (this.pageNumber * this.getLimit()) < total
            },
        },
        methods: {
            pgettext: window.pgettext,
            interpolate: window.interpolate,

            getLimit() {
                if (window.matchMedia('(min-width: 1280px)').matches) {
                    return window.pagination_count
                }
                return window.pagination_count_mobile
            },

            getOffset() {
                return (this.pageNumber - 1) * this.getLimit()
            },

            fireSearch(search) {
                this.cleanSearch()
                this.search = search
                this.fetchPosts()
            },

            getDefaultUrl() {
                return `blog/?type=app_blog.BlogPost&descendant_of=${this.page.id}&fields=*`
            },

            morePosts() {
                if (this.loading) return
                this.pageNumber++
                this.fetchPosts()
            },

            searchFieldLabel() {
                return this.interpolate((this.pgettext && typeof this.pgettext === 'function' ? this.pgettext('Blog Index Page search input place holder', 'In %s search') : 'In News and Stories search'), [this.page.title])
            },

            async fetchPosts() {
                let url = this.getDefaultUrl()

                this.loading = true

                let offset = this.getOffset()
                let limit = this.getLimit()
                url = url + `&offset=${offset}&limit=${limit}`

                if(this.search != '') {
                    url = url + `&search=${this.search}`
                }

                // Call server api
                const res = await axios.get(window.api_url + `${url}`).catch(errors => {
                });

                if (this.pageNumber == 1) {
                    this.apiResponse = res.data
                } else if (Object.prototype.hasOwnProperty.call(this.apiResponse, 'items')) {
                    this.apiResponse.items = this.apiResponse.items.concat(res.data.items)
                }

                this.apiResponse.items.forEach(page => {
                    let findPage = this.$parent.$parent.json_data.find(o => o.id === page.id);
                    if (!findPage) {
                        this.$parent.$parent.json_data.push(page)
                    }
                })

                this.loading = false
            },

            cleanSearch() {
                this.search = ''
                this.pageNumber = 1
                this.apiResponse = {}
            }
        },

        created() {
            this.pageNumber = 1
            this.fetchPosts()
        },
    }
</script>