<template>
    <div class="app-container" :class="{'show-overlay': menuOpen}">
        <Topbar @open-modal="openModal" :show="showTopbar" :atTop="scrollAtTop" :home="active_id === home_id"/>
        <TitleBar :goTo="goTo" :active="active_id"/>
        <div class="app-pages">
            <div v-for="page in pages" v-bind:key="page.id" v-touch:swipe.left="() => swipeLeft()"
                 v-touch:swipe.right="() => swipeRight()" class="app-page" :data-id="page.id"
                 :class="[{'home-page': page.meta.type === 'app_headless.AppHomePage'}, page.animation, { [just_left_class]: page.id === just_left_id, [active_class]: page.id === active_id, 'active': page.id === active_id }]"
                 :ref="page.id" @scroll="onScroll" :style="{zIndex: 1000-zIndex.indexOf(page.id)}">
                <component :is="dynamicComponentHeading(page.id)" class="page-title hidden">{{ page.title }}</component>
                <Pages :page="page" :goTo="goTo" :updateScrollPosition="updateScrollPosition"/>
                <Footer :value="footer_data"/>
                <div class="page-overlay"></div>
            </div>
        </div>
        <SearchPage @close-modal="show_search_modal=false" v-show="show_search_modal" :showSearchModal="show_search_modal" />
        <div class="app-overlay" v-on:click="menuOpen = !menuOpen"/>
        <Menu @open-modal="openModal" :goTo="goTo" :show="menuOpen"/>
    </div>
</template>

<script>
    import Menu from './Menu.vue'
    import Pages from './Pages'
    import SearchPage from "./pages/SearchPage";
    import Topbar from './Topbar'
    import Footer from './Footer'
    import TitleBar from './TitleBar'
    import '../../../../app_base/static/app_base/styles/scss/main.scss';
    import axios from "axios";
    import * as Sentry from "@sentry/vue";

    export default {
        data: function () {
            return {
                json_data: this.$parent.json_data,
                footer_data: this.$parent.footer_data,
                contact_form_data: this.$parent.contact_form_data,
                social_channels_data: this.$parent.social_channels_data,
                fact_order: this.$parent.fact_order,
                interview_order: this.$parent.interview_order,
                focus_order: this.$parent.focus_order,
                blog_index_page: this.$parent.blogIndexPage,
                // dynamicComponentWrapper:'',
                page_type: "",
                pages: [],
                title: "",
                zIndex: [],
                active_id: 0,
                active_class: "",
                active_url: "",
                just_left_id: 0,
                just_left_class: "",
                home_id: window.app_home_id,
                progress: [],
                lazyload: [],
                responsive_size: "",
                menuOpen: false,
                show_progress: false,
                resetAnims: null,
                hash: "",
                scroll: 0,
                lastScrollDown: 0,
                scrollAtTop: true,
                showTopbar: true,
                resetAccordion: false,
                language: window.default_language,
                show_lang_switcher: window.show_lang_switcher,
                isTouch: ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0),
                // NOTE - To have translations on vue components, pgettext needs to be defined here
                // same goes to interpolate, which is used on other components
                pgettext: window.pgettext,
                show_search_modal: false
            }
        },
        components: {
            Menu,
            Topbar,
            Pages,
            Footer,
            TitleBar,
            SearchPage
        },
        methods: {
            openModal() {
                this.show_search_modal = true
            },
            // this function runs at the reload of the page or when the user first lands on it through an external method
            // It reads the url on the browser to know which page it should render to the user
            async readUrl(preventPushState) {
                if (preventPushState) this.resetAccordion = true;
                if (window.page_preview && Object.keys(window.page_preview).length > 0) {
                    this.json_data.forEach((page, i) => {
                        if (page.id == window.page_preview.id) {
                            this.goTo({id: window.page_preview.id, preventPushState: preventPushState})
                        }
                    })
                } else {
                    if (window.location.pathname !== "/") {
                        let uri = window.location.pathname;
                        if (window.location.hash) this.hash = window.location.hash.replace("#", "");

                        if (uri[uri.length - 1] !== "/") uri = uri + "/";
                        let page = this.json_data.find(o => o.url === uri);
                        if (page) this.goTo({id: page.id, ignore_click: true, preventPushState: preventPushState})
                        else {
                            if (window.current_page.type == 'app_blog.BlogPost') {
                                page = await this.loadPage(window.current_page.id)
                                if (page) {
                                    this.goTo({id: page.id, ignore_click: true, preventPushState: preventPushState})
                                } else {
                                    this.goTo({
                                        id: this.home_id,
                                        ignore_click: true,
                                        preventPushState: preventPushState
                                    })
                                }
                            } else {
                                this.goTo({id: this.home_id, ignore_click: true, preventPushState: preventPushState})
                            }
                        }
                    } else {
                        this.goTo({id: this.home_id, preventPushState: preventPushState})
                    }
                }
            },

            async loadPage(page_id) {
                try {
                    let url = `pages/${page_id}/`

                    // Call server api
                    const res = await axios.get(window.api_url + `${url}`).catch(errors => {
                    });

                    let page = res.data

                    // Push Page to Store json_data
                    this.json_data.push(page)

                    return page
                } catch (e) {
                    console.log(e)
                    return false
                }
            },

            // This is the most important function, <a> links will use this to navigate inside the site
            async goTo({id, mode, preventPushState, customHash, ignore_click}) {
                if (typeof id !== 'number') id = parseInt(id);

                if (id === this.active_id) {
                    return;
                }

                let exist_page = this.json_data.find(o => o.id === id);
                if (!exist_page) {
                   await this.loadPage(id)
                }

                // Render the new page if not rendered already
                this.renderPage({id: id});

                // after mounting a page, get it by id to use its title to push to history
                // so user can click back on the browser and return to previous page
                let page = this.pages.find(o => o.id === id);
                if (!page) return;

                // Check if custom of self hash exists in order to set it in push state and url                
                let addHashToURL = false;
                if (ignore_click) {
                    addHashToURL = this.hash ? this.hash : "";
                } else if (customHash && !ignore_click) {
                    addHashToURL = customHash ? customHash : this.hash ? this.hash : "";
                }

                // If manual click but does not have hash, then reset this.hash value oterwise it may become an issue on readUrl
                if (!ignore_click && !customHash) {
                    window.location.hash = ""
                    this.hash = ""
                }

                // If not preventPushState then push
                if (!preventPushState) history.pushState({page: id}, page.title, `${page.url}${addHashToURL ? "#" + addHashToURL : ""}`)

                this.resetAccordion = true;
                // enable lazyloading images and components for this page
                if (!this.lazyload.includes(page.id)) this.lazyload.push(page.id);

                // Add classes to pages to start animation effects
                this.setActivePage(id, page.url, mode)
                // set titles
                this.title = page.title;
                document.title = page.title + (window.site_name ? ' - ' + window.site_name : '');
                document.getElementById("canonical-rel").href = page.meta.html_url
                document.getElementById("robots-index").content = page.show_in_sitemap ? 'index' : 'noindex';

                this.page_type = this.getPageType(page);

                // Render Neighbors only if it is not bot
                if (!window.is_bot) {
                    this.renderNeighbors(id);
                }


                if (addHashToURL && this.$refs[this.active_id] && this.$refs[this.active_id][0]) {
                    setTimeout(() => {
                        let blockSlugElement = this.$refs[this.active_id][0].querySelectorAll(decodeURI(`a#${addHashToURL}`))[0]
                        if (blockSlugElement) {
                            this.$refs[this.active_id][0].scrollTop = blockSlugElement.offsetTop - 150;
                        }
                    }, 150);
                }
                this.$Lazyload.lazyLoadHandler();
            },
            updateScrollPosition(pageComponent, customHash) {
                let hash = customHash ? customHash : window.location.hash;
                if (hash) {
                    let elemId = hash.replace("#", "");
                    setTimeout(() => {
                        let anchorElement = pageComponent.querySelectorAll(decodeURI(`a#${elemId}`))[0];
                        if (anchorElement && pageComponent.parentElement) {
                            pageComponent.parentElement.scrollTop = anchorElement.parentElement.offsetTop - 150;
                        }
                    }, 150);

                }
            },
            getPageType(page) {
                switch (page.meta.type) {
                    case "app_headless.AppFactPage":
                        return "fact";
                    case "app_headless.AppInterviewPage":
                        return "interview";
                    case "app_headless.AppFocusPage":
                        return "focus";
                    default:
                        return "";
                }
            },
            // Swipe logic, only used for facts on portable devices
            swipeLeft() {
                console.log('Trying to swipeLeft');
                if (this.isTouch && this[this.page_type + "_order"]) {
                    let next_page = this[this.page_type + "_order"][this[this.page_type + "_order"].findIndex(o => o.id === this.active_id) + 1];
                    if (next_page) this.swipeTo(next_page.id, "swipe-left");
                }
            },
            swipeRight() {
                console.log('Trying to swipeRight');
                if (this.isTouch && this[this.page_type + "_order"]) {
                    let prev_page = this[this.page_type + "_order"][this[this.page_type + "_order"].findIndex(o => o.id === this.active_id) - 1];
                    if (prev_page) this.swipeTo(prev_page.id, "swipe-right");
                }
            },
            swipeTo(id, anim) {
                this.goTo({id: id, mode: anim});
            },
            setActivePage(id, url, mode) {
                clearTimeout(this.resetAnims);
                // Logic to detect navigation and add animations to pages accordingly
                // It reads the passed mode, defined on templates, and adds classes to pages for animations.
                // DICT: "current page": The page the user is looking at || "active page": The new page entering frame.
                // Classes:
                //  - 'entering': trigger animation
                //  - 'leaving': trigger animation on reverse
                //  - 'revealing': doesn't animate, tells the page it will be appearing, used for overlay opacity
                //  - 'hiding': same as above
                //  - 'instant': doesnt animate or use overlay, page shows up instantly
                switch (mode) {
                    // clicking "previous" will add 'leaving' to current page and 'revealing' to active page
                    case "prev":
                        this.zIndex.splice(this.zIndex.indexOf(id), 1);
                        this.zIndex.unshift(id)
                        this.zIndex.splice(this.zIndex.indexOf(this.active_id), 1);
                        this.zIndex.unshift(this.active_id)
                        this.setIds(id);
                        this.active_class = "revealing";
                        this.just_left_class = "leaving";
                        this.resetAnimClasses(id);
                        break;
                    // 'next' will add 'entering' to active page and leave current page with 'hiding'
                    // same thing to applied to modes: 'enter', 'home', 'back' and 'child'
                    case "next":
                    case "enter":
                    case "home":
                    case "back":
                    case "child":
                    case "link":
                        this.zIndex.splice(this.zIndex.indexOf(id), 1);
                        this.zIndex.unshift(id)
                        this.setIds(id);
                        this.just_left_class = "hiding";
                        this.active_class = "entering";
                        this.resetAnimClasses(id);
                        break;
                    case "swipe-right":
                        this.zIndex.splice(this.zIndex.indexOf(id), 1);
                        this.zIndex.unshift(id)
                        this.setIds(id);
                        this.just_left_class = "caroussel caroussel-leave-right";
                        this.active_class = "caroussel caroussel-enter-left";
                        this.resetAnimClasses(id);
                        break;
                    case "swipe-left":
                        this.zIndex.splice(this.zIndex.indexOf(id), 1);
                        this.zIndex.unshift(id)
                        this.setIds(id);
                        this.just_left_class = "caroussel caroussel-leave-left";
                        this.active_class = "caroussel caroussel-enter-right";
                        this.resetAnimClasses(id);
                        break;
                    // 'default' will fade in the page, currently used when loading the app
                    default:
                        this.zIndex.splice(this.zIndex.indexOf(id), 1);
                        this.zIndex.unshift(id)
                        this.setIds(id);
                        this.just_left_class = "hiding";
                        this.active_class = "instant";
                        this.resetAnimClasses(id);
                        break;
                }
                this.active_url = url;
            },
            resetAnimClasses(id) {
                // After the animation time concluded, remove classes so the next transition plays correctly
                this.resetAnims = setTimeout(() => {
                    this.zIndex.splice(this.zIndex.indexOf(id), 1);
                    this.zIndex.unshift(id)
                    this.active_class = "";
                    this.just_left_class = "";
                }, 600);
            },
            setIds(id) {
                this.just_left_id = this.active_id
                this.active_id = id;
            },
            renderPage({id, slug}) {
                // Add current page id to rendered Pages array                
                let page = id ? this.json_data.find(o => o.id === id) : this.json_data.find(o => o.meta.slug === slug);

                if (!page) return;

                if (!this.pages.find(o => o.id === page.id)) {
                    // Get children data for this page
                    let childList = [];
                    page.children.forEach(child => {
                        childList.push(this.json_data.find(o => o.id === child))
                    });
                    page.children_data = childList;

                    // Test if it is iin order to add to array or replace page
                    if (window.is_bot) {
                        // If is bot then always keep one page on pages array
                        this.pages = [page];
                    } else {
                        // Push processed page to pages array
                        this.pages.unshift(page);
                    }

                    if (page.show_progress) this.progress.push({id: page.id, progress: 0, shown: false});
                    this.zIndex.push(page.id)
                }
            },
            renderNeighbors(id) {
                let page = this.pages.find(o => o.id === id);
                // if page is a fact/focus/interview page, render previous and next fact pages
                let type = page.meta.type.replace(/app_headless.App|Page/gi, "").toLowerCase();
                if (["fact", "interview", "generic"].includes(type)) {
                    if (type === "generic") type = "focus";
                    let page_index = this[type + "_order"].findIndex(o => o.id === id)
                    let prev = this[type + "_order"][page_index - 1];
                    let next = this[type + "_order"][page_index + 1];
                    if (prev) {
                        this.renderPage({id: prev.id})
                    }
                    if (next) {
                        this.renderPage({id: next.id})
                    }
                }
            },
            onScroll() {
                // Topbar hide/show
                let ref = this.$refs[this.active_id][0];
                if (ref.scrollTop < 260) {
                    this.scrollAtTop = true;
                    this.showTopbar = true;
                } else if (ref.scrollTop < this.lastScrollDown - 100) {
                    this.scrollAtTop = false;
                    this.showTopbar = false;
                    this.lastScrollDown = ref.scrollTop;
                } else if (ref.scrollTop > this.lastScrollDown) {
                    this.scrollAtTop = false;
                    this.showTopbar = true;
                    this.lastScrollDown = ref.scrollTop;
                }
                // Update progress bar on scroll for pages that use it
                if (this.progress.find(o => o.id === this.active_id)) {
                    const progress = ref.scrollTop / (ref.scrollHeight - ref.clientHeight)
                    if (progress > 1) {
                        this.progress.find(o => o.id === this.active_id).progress = 1
                    } else if (progress < 0) {
                        this.progress.find(o => o.id === this.active_id).progress = 0
                    } else {
                        this.progress.find(o => o.id === this.active_id).progress = progress
                    }
                    if (!this.progress.find(o => o.id === this.active_id).shown) this.progress.find(o => o.id === this.active_id).shown = true;
                }
            },
            getUrl(id) {
                let page = this.json_data.find(o => o.id === id);
                return page.url;
            },
            handleResize() {
                // check size on window resize
                if (window.matchMedia('(min-width: 1440px)').matches) {
                    this.responsive_size = "large"
                } else if (window.matchMedia('(min-width: 768px)').matches) {
                    this.responsive_size = "medium"
                } else {
                    this.responsive_size = "small"
                }
            },
            dynamicComponentWrapper(pageID) {
                return this.active_id == pageID ? 'main' : 'aside'
            },
            dynamicComponentHeading(pageID) {
                return this.active_id == pageID ? 'h1' : 'p'
            }
        },
        computed: {},
        created() {
            // check language
            if (window.app_languages.includes(window.location.pathname.split("/")[1])) this.language = window.location.pathname.split("/")[1];
            else this.language = "de";

            // add listener for popstate
            window.addEventListener('popstate', () => {
                this.readUrl(true)
            })
            // Structure footer and contact data
            if (this.language === "fr") {
                if (this.footer_data.translations[0]) this.footer_data = this.footer_data.translations[0];
                if (this.contact_form_data.translations[0]) this.contact_form_data = this.contact_form_data.translations[0];
            }

            // Code for getting urls on footer menu just from page ids
            if (this.footer_data) {
                for (let i = 0; i < this.footer_data.menu.length; i++) {
                    let page = this.json_data.find(o => o.id === this.footer_data.menu[i].value)

                    // Populate menu if page is found in json data
                    if (page) {
                        this.footer_data.menu[i] = {
                            id: this.footer_data.menu[i].value,
                            url: page.url
                        }
                    }
                }
            }

            window.addEventListener('resize', this.handleResize);
            // Read url and render current page

            this.handleResize()

            this.readUrl()
        },
    }
</script>