<template>
    <div class="module featured-post" :id="post.id" v-on:click.stop.prevent="click(post.id)">
        <a v-if="post.slug" :id='[post.slug]' class="module-anchor-link">&nbsp;</a>         
        <div class="grid-container">
            <div class="grid-x align-center">
                <div class="cell small-12 medium-10 large-7 post-thumbnail">
                    <PictureSnippet :res="$parent.$parent.$parent.responsive_size" :image="post.featured_image" :alt="post.featured_image_alt" />
                </div>
                <div class="cell small-12 medium-10 large-5 color-bg-gray-one grid-padding-x post-text">
                    <p class="post-subtitle font-size-static-small show-for-large text-uppercase">{{parent.title}}</p>
                    <p class="post-published font-size-static-small hide-for-large">{{ post.first_published_at }}</p>
                    <h6 class="post-title">{{post.title}}</h6>
                    <p class="post-lead-text text" v-html="post.lead_text" />
                    <p class="post-reading-time link-time font-size-static-small">{{post.reading_time}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PictureSnippet from './PictureSnippet'
    import axios from "axios";

    export default {
        props: {
            post: {
                type: Object
            },
            parent: {
                type: Object
            },
        },
        data: function () {
            return {}
        },
        components: {
            // Picture43or169Snippet,
            PictureSnippet
        },
        methods: {
            // Function linked to Base's goTo to navigate inside the site
            click(page_id){
                this.$parent.$parent.$parent.goTo({id: page_id, mode: "link"})
            },
            async loadPage() {
                try {
                    let url = `pages/${this.post.id}/`

                    // Call server api
                    const res = await axios.get(window.api_url + `${url}`).catch(errors => {
                    });

                    let page = res.data

                    // Push Page to Store json_data
                    this.$parent.$parent.$parent.json_data.push(page)
                } catch (e) {
                    console.log(e)
                }
            }
        },
        created() {
            let findPage = this.$parent.$parent.$parent.json_data.find(o => o.id === this.post.id);
            if (!findPage) {
                this.loadPage()
            }
        }
    }
</script>
