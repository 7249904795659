<template>

    <figure>
        <picture v-lazy-container="{ selector: 'img' }">
            <!-- <img :alt="image.title" :data-src="curr_image.url.startsWith('http') ? curr_image.url : mediaURLPrefix+curr_image.url" :width="curr_image.width" :height="curr_image.height" :data-loading="mediaURLPrefix+image.renditions.small.url" /> -->
            <img :data-src="curr_image.url.startsWith('http') ? curr_image.url : mediaURLPrefix+curr_image.url" :width="curr_image.width" :height="curr_image.height" :alt="alt" />
        </picture>
        <figcaption v-if="caption" v-html="caption" />
    </figure>

</template>

<script>
    export default {
        props: {
            alt: {
                type: String
            },
            image: {
                type: Object
            },
            caption: {
                type: String
            },
            res: {
                type: String
            }
        },
        data: function () {
            return{
                mediaURLPrefix: window.mediaURLPrefix,
                curr_image: {}
            }
        },
		watch: { 
			// Refresh data on prop change | update tabindex
			$props: {
				handler: function() {
                    if (this.res === "large") {
                        this.curr_image = this.image.renditions.large
                    }else if(this.res === "medium"){
                        this.curr_image = this.image.renditions.medium
                    }else{
                        this.curr_image = this.image.renditions.small
                    }
				},
				immediate: true,
				deep: true
			},
		}
    }
</script>