<template>
    <div class="post-card" v-on:click.stop.prevent="click()">
        <a :id="post.id" :href="post.url" v-on:click.prevent>
            <PictureThumbnail :image="curr_image"/>
            <div class="link-text flex-container flex-dir-column link-text-padding">
                <p class="link-title reset-padding"><b v-html="post.title"></b></p>
                <p class="link-excerpt body-two" v-html="post.lead_text"></p>
                <small class="link-time text-right">{{post.reading_time}}</small>
            </div>
        </a>
    </div>    
</template>

<script>
    import PictureThumbnail from './PictureThumbnail'
    export default {
        props: {
            post: {
                type: Object
            },
            res: {
                type: String
            }
        },
        components: {
            PictureThumbnail,
        },        
        data: function () {
            return {
                mediaURLPrefix: window.mediaURLPrefix,
                curr_image: {}
            }
        },
        watch: {
            // Refresh data on prop change | update tabindex
            $props: {
                handler: function () {
                    this.curr_image = this.post.head_image.renditions.thumbnail
                },
                immediate: true,
                deep: true
            },
        },
        methods: {
            click() {
                this.$parent.$parent.goTo({id: this.post.id, mode: 'swipe-left'})
            }
        }
    }
</script>