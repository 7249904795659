<template>
	<div class="page fact-page">

		<SharingLinks :page="page" />
		<div class="page-content">
			<div class="grid-container grid-x">
				<div class="cell small-12 medium-12 large-8 margin-auto">
					<h3 class="page-title fact-page-title" v-html="page.full_title ? page.full_title : page.title"/>

					<div class="section-featured-image">
						<PictureSnippet v-if="$parent.$parent.lazyload.includes(page.id)" :res="$parent.$parent.responsive_size" :image="page.head_image" :alt="page.featured_image_alt" />
						<p v-if="page.reading_time" class="reading-time">{{page.reading_time}}</p>
						<SharingLinks :page="page" />
					</div>
					
				</div>
			</div>
			<div v-if="page.lead_text" class="grid-container grid-x">
				<div class="cell small-12 medium-10 large-8 lead-text" :class="this.$root.$children[0].$children[0].active_id === this.page.id && 'h-title'" v-html="page.lead_text" />
			</div>
			<Blocks :pageid="page.id" :body="page.body" :data="$parent.$parent.json_data" @goTo="$parent.goTo" />
		</div>

	</div>
</template>

<script>
    import Blocks from '../Blocks'
	import PictureSnippet from '../snippets/PictureSnippet'
	import SharingLinks from '../snippets/SharingLinks'
	
	export default {
		props: {
			page: {
				type: Object
			},
		},
		data: function () {
			return { 
				pgettext: window.pgettext,
				mediaURLPrefix: window.mediaURLPrefix,
			}
		},
		components: {
			Blocks,
			PictureSnippet,
			SharingLinks
		},
		methods: {
			getUrl(id) {
                let page = this.$parent.$parent.json_data.find(o => o.id === id);
                return page.url;
			}
		},
		mounted() {
			if (this.$root.$children[0].$children[0].active_id === this.page.id) 
				this.$root.$children[0].$children[0].updateScrollPosition(this.$el)

		}
	}
</script>