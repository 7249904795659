<template>
	<div class="app-title-bar-container" :class="[{'show': show}, {'hiding': !show}, {'remove': remove}, {'on-swiper': on_fact || on_interview || on_focus || on_post}]">
		<div class="grid-container grid-x app-title-bar">
			<div class="cell small-12 medium-12 large-10 margin-auto">
				<a :class="{'hide-arrow': !prev_page.id}" :id="prev_page.id" :href="prev_page.url" v-on:click.stop.prevent="click(prev_page, 'swipe-right')" v-on:keyup.space.stop.prevent="click(prev_page, 'swipe-right')">
					<svg width="34" height="22" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 11L31.111 11" stroke="#2C2C2C" stroke-width="2.5" stroke-miterlimit="10"/>
						<path d="M22.2199 21.0009L31.6687 11.0008L22.2215 1.00085" stroke="#2C2C2C" stroke-width="2.5"/>
					</svg>
				</a>
				<h2>{{ title }}</h2>
				<a :class="{'hide-arrow': !next_page.id}" :id="next_page.id" :href="next_page.url" v-on:click.stop.prevent="click(next_page, 'swipe-left')" v-on:keyup.space.stop.prevent="click(next_page, 'swipe-left')">
					<svg width="34" height="22" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 11L31.111 11" stroke="#2C2C2C" stroke-width="2.5" stroke-miterlimit="10"/>
						<path d="M22.2199 21.0009L31.6687 11.0008L22.2215 1.00085" stroke="#2C2C2C" stroke-width="2.5"/>
					</svg>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	// This component is the black title bar that shows up on generic pages and numbers fact pages.
	// It needs to be respond to changes on props, updating on every page change

	export default {
		props: {
			active: {
				type: Number,
			},
			goTo: {
				type: Function
			}
		},
		data: function(){
			return{
				page: {},
				title: "",
				remove: true,
				show: false,
				on_fact: true,
				on_post: false,
				prev_page: {},
				next_page: {},
				pgettext: window.pgettext,
			}
		},
		methods: {
			// Function linked to Base's goTo to navigate inside the site, in this case it also states direction
			// so it shows the slide animation for fact pages arrows
            click(page, direction){
				this.goTo({id: page.id, mode: direction})
			},
		},
		watch: { 
			// Refresh on prop change | get page title and fact siblings if fact page
			$props: {
				handler: function(newActive, oldActive) {
					this.page = this.$parent.pages.find(o => o.id === newActive.active);
					if(this.page === undefined) return
					// if title bar needs to hide, don't erase data
					let willHide = ['app_headless.AppHomePage'].includes(this.page.meta.type);
					if(willHide){
						this.show = false;
						setTimeout(() => {
							this.remove = true;
						}, 300)
					}else{
						this.on_fact = this.page.meta.type === 'app_headless.AppFactPage';
						this.on_focus = this.page.meta.type === 'app_headless.AppFocusPage';
						this.on_interview = this.page.meta.type === 'app_headless.AppInterviewPage';
						this.on_post = this.page.meta.type === 'app_blog.BlogPost';
						// get prefixNum as page title on fact pages instead of title
						this.title = this.page ? this.page.title : '';
						this.remove = false;
						this.show = true;
						let prev_page;
						let next_page;
						if(this.on_fact){
							let factPagePrefixTitlePart = this.page && this.page.prefixNum != undefined ? this.page.prefixNum : '';
							this.title = this.pgettext('Fact title bar', 'FACT') + ' ' + factPagePrefixTitlePart ;
							prev_page = this.$parent.fact_order[this.$parent.fact_order.findIndex(o => o.id === this.page.id)-1];
							next_page = this.$parent.fact_order[this.$parent.fact_order.findIndex(o => o.id === this.page.id)+1];
						}
						if(this.on_interview){
							this.title = this.page.nav_bar_title ? this.page.nav_bar_title : this.page ? this.page.title : '';
							prev_page = this.$parent.interview_order[this.$parent.interview_order.findIndex(o => o.id === this.page.id)-1];
							next_page = this.$parent.interview_order[this.$parent.interview_order.findIndex(o => o.id === this.page.id)+1];
						}
						if(this.on_focus){
							this.title = this.page.nav_bar_title ? this.page.nav_bar_title : this.page ? this.page.title : '';
							prev_page = this.$parent.focus_order[this.$parent.focus_order.findIndex(o => o.id === this.page.id)-1];
							next_page = this.$parent.focus_order[this.$parent.focus_order.findIndex(o => o.id === this.page.id)+1];
						}
						if(this.on_post) {
							this.title = this.$parent.blog_index_page ? this.$parent.blog_index_page.title : '';
							prev_page = this.$parent.blog_index_page;
						}
						// load next and previous pages to get url for a href
						if(prev_page){
							prev_page = this.$parent.json_data.find(o => o.id === prev_page.id);
							this.prev_page = prev_page
						}else this.prev_page = {}
						if(next_page){
							next_page = this.$parent.json_data.find(o => o.id === next_page.id);
							this.next_page = next_page
						}else this.next_page = {}
					}
				},
				deep: true,
				immediate: true,
			},
		}
	}
</script>