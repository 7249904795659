<template>
    <div class="app-modal page-search" :class="showSearchModal && 'modal-content-shown'">
        <div class="app-topbar showing at-top">
            <div class="top-bar top-bar-white grid-container">
                <div class="top-bar-left">
                    <a v-on:click.stop.prevent="closeModal()">
                        <svg class="beton-logo" width="189" height="22" viewBox="0 0 189 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.013249 0.582886H7.68443C8.57873 0.582886 9.46642 0.642506 10.3673 0.768372C11.2683 0.887613 12.0765 1.14597 12.7919 1.52357C13.514 1.90116 14.0837 2.4245 14.5342 3.11345C14.9714 3.79577 15.2032 4.69008 15.2032 5.80962C15.2032 6.96891 14.872 7.93609 14.2294 8.70453C13.5802 9.47297 12.7257 10.0228 11.6525 10.354V10.4136C12.3348 10.513 12.9443 10.6919 13.4941 10.9767C14.044 11.255 14.5209 11.6127 14.9184 12.0499C15.3225 12.4871 15.6272 12.9906 15.8326 13.5735C16.0379 14.1565 16.1505 14.7726 16.1505 15.4284C16.1505 16.5148 15.9187 17.4157 15.455 18.1511C14.9913 18.8798 14.395 19.4693 13.6531 19.9066C12.9178 20.357 12.0897 20.675 11.1623 20.8737C10.2481 21.0659 9.33393 21.1652 8.41975 21.1652H0V0.582886H0.013249ZM4.53779 8.81052H7.81692C8.16801 8.81052 8.51249 8.7774 8.85034 8.69128C9.19481 8.61841 9.49954 8.48592 9.76452 8.31368C10.0295 8.14144 10.2547 7.90959 10.4203 7.61811C10.5793 7.32663 10.6655 6.98216 10.6655 6.57144C10.6655 6.14084 10.5661 5.78975 10.3872 5.51152C10.2017 5.23329 9.96325 5.00805 9.6784 4.84907C9.38692 4.69008 9.0557 4.57746 8.69135 4.51784C8.327 4.44497 7.96265 4.41847 7.61818 4.41847H4.55103V8.81052H4.53779ZM4.53779 17.3296H8.59861C8.94971 17.3296 9.30743 17.2965 9.65853 17.2104C10.0096 17.1375 10.3409 17.005 10.6323 16.8063C10.9238 16.6076 11.1557 16.3558 11.3412 16.0511C11.5266 15.7464 11.6194 15.3688 11.6194 14.9183C11.6194 14.4281 11.5001 14.0373 11.255 13.7458C11.0099 13.441 10.7052 13.2224 10.3409 13.0634C9.9765 12.9045 9.57241 12.8051 9.14844 12.7455C8.72447 12.6859 8.32038 12.6594 7.95603 12.6594H4.52454V17.3363H4.53779V17.3296Z" fill="#2C2C2C"/>
                            <path d="M19.5271 0.582886H33.4916V4.76957H24.0516V8.60516H32.9682V12.7918H24.0516V16.9785H34.0149V21.1652H19.5271V0.582886Z" fill="#2C2C2C"/>
                            <path d="M43.7485 4.60396H37.8792V0.582886H54.1489V4.59733H48.2796V21.1652H43.7418V4.60396H43.7485Z" fill="#2C2C2C"/>
                            <path d="M55.2664 10.8907C55.2664 9.24116 55.5446 7.75064 56.0944 6.41249C56.6443 5.06772 57.4127 3.93493 58.3997 2.99425C59.3868 2.05357 60.5593 1.3315 61.9107 0.834661C63.2621 0.331199 64.7526 0.0794678 66.3491 0.0794678C67.9589 0.0794678 69.4362 0.337823 70.7876 0.834661C72.139 1.33812 73.3115 2.05357 74.2985 2.99425C75.2856 3.93493 76.054 5.06772 76.6039 6.41249C77.1537 7.75727 77.4319 9.24116 77.4319 10.8907C77.4319 12.5402 77.1537 14.0241 76.6039 15.3688C76.054 16.7136 75.2856 17.8464 74.2985 18.7871C73.3115 19.7277 72.139 20.4498 70.7876 20.9467C69.4362 21.4501 67.9457 21.7019 66.3491 21.7019C64.7394 21.7019 63.2621 21.4435 61.9107 20.9467C60.5593 20.4432 59.3868 19.7277 58.3997 18.7871C57.4127 17.8464 56.6443 16.7136 56.0944 15.3688C55.5446 14.0241 55.2664 12.5203 55.2664 10.8907ZM59.9698 10.8907C59.9698 11.8578 60.1155 12.7455 60.4202 13.5537C60.7249 14.3619 61.1555 15.0575 61.712 15.6404C62.2751 16.2234 62.9442 16.6871 63.7391 17.0183C64.5208 17.3496 65.3952 17.5218 66.3756 17.5218C67.3428 17.5218 68.2173 17.3628 69.0122 17.0183C69.7939 16.6871 70.463 16.2234 71.026 15.6404C71.5891 15.0508 72.0131 14.3487 72.3178 13.5537C72.6225 12.7455 72.7683 11.8578 72.7683 10.8907C72.7683 9.93673 72.6225 9.0623 72.3178 8.24086C72.0131 7.42604 71.5825 6.72385 71.026 6.12764C70.463 5.54468 69.7939 5.08097 69.0122 4.74974C68.2305 4.41852 67.3561 4.25953 66.3756 4.25953C65.4151 4.25953 64.534 4.41852 63.7391 4.74974C62.9574 5.08097 62.2883 5.54468 61.712 6.12764C61.1489 6.71722 60.7249 7.41942 60.4202 8.24086C60.1287 9.05567 59.9698 9.93673 59.9698 10.8907Z" fill="#2C2C2C"/>
                            <path d="M80.8931 0.582886H87.0539L95.7982 14.8918H95.8578V0.582886H100.382V21.1652H94.4534L85.4772 6.51844H85.4176V21.1652H80.8931V0.582886Z" fill="#2C2C2C"/>
                            <path d="M136.513 21.9999C130.451 21.9999 125.522 17.0647 125.522 11.0033C125.522 4.94185 130.458 0.0065918 136.513 0.0065918C142.574 0.0065918 147.503 4.94185 147.503 11.0033C147.503 17.0647 142.574 21.9999 136.513 21.9999ZM136.513 1.46398C131.259 1.46398 126.98 5.74341 126.98 11.0033C126.98 16.2631 131.259 20.5426 136.513 20.5426C141.766 20.5426 146.045 16.2631 146.045 11.0033C146.045 5.74341 141.772 1.46398 136.513 1.46398Z" fill="#2C2C2C"/>
                            <path d="M177.73 21.9999C171.669 21.9999 166.74 17.0647 166.74 11.0033C166.74 4.94185 171.669 0.0065918 177.73 0.0065918C183.792 0.0065918 188.72 4.94185 188.72 11.0033C188.72 17.0647 183.792 21.9999 177.73 21.9999ZM177.73 1.46398C172.477 1.46398 168.198 5.74341 168.198 11.0033C168.198 16.2631 172.47 20.5426 177.73 20.5426C182.99 20.5426 187.263 16.2631 187.263 11.0033C187.263 5.74341 182.984 1.46398 177.73 1.46398Z" fill="#2C2C2C"/>
                            <path d="M120.781 10.6191L119.807 9.53266C120.781 8.65823 121.344 7.39957 121.344 6.08792C121.344 3.53749 119.27 1.45739 116.72 1.45739C114.169 1.45739 112.096 3.53086 112.096 6.08792H110.638C110.638 2.7293 113.368 0 116.72 0C120.072 0 122.801 2.7293 122.801 6.08792C122.801 7.81692 122.066 9.46642 120.781 10.6191Z" fill="#2C2C2C"/>
                            <path d="M121.635 21.3375H108.028L119.787 9.57239L120.82 10.5992L111.546 19.8801H121.635V21.3375Z" fill="#2C2C2C"/>
                            <path d="M156.836 22.0001C153.47 22.0001 150.734 19.2641 150.734 15.8989H152.192C152.192 18.4626 154.272 20.5427 156.836 20.5427C159.393 20.5427 161.473 18.456 161.473 15.8989C161.473 13.3418 159.393 11.2551 156.836 11.2551C155.663 11.2551 154.543 11.6923 153.682 12.4873L152.695 11.4207C153.821 10.3741 155.292 9.79773 156.836 9.79773C160.201 9.79773 162.93 12.5336 162.93 15.8989C162.93 19.2641 160.194 22.0001 156.836 22.0001Z" fill="#2C2C2C"/>
                            <path d="M153.702 12.4739L152.669 11.4405L161.976 2.12646H151.847V0.669067H165.494L153.702 12.4739Z" fill="#2C2C2C"/>
                        </svg>
                    </a>
                </div>
                <div class="top-bar-right">
                    <div class="top-bar-menu-buttons">
                        <button class="button button-menu" v-on:click="closeModal()">
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 1.7L15.3 0L8.5 6.8L1.7 0L0 1.7L6.8 8.5L0 15.3L1.7 17L8.5 10.2L15.3 17L17 15.3L10.2 8.5L17 1.7Z" fill="#2C2C2C"/>
                            </svg>
                        </button>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="page">
            <div class="grid-container grid-x">
                <div class="cell small-12 medium-10 large-8 margin-auto">
                    <div class="search-input-container">
                        <SearchForm
                            :placeholder="pgettext('Search Page - Input Search Placeholder', 'Suchen')"
                            :loading="loading"
                            :focusSearchField="showSearchModal"
                            :clearSearchField="clearSearchField"
                            @fire-search="fireSearch"
                            @clean-search="cleanSearch" />
                    </div>

                    <div v-if="apiResponse.items && apiResponse.items.length > 0" class="search-results-container">
                        <p class="search-results-found">{{apiResponse.meta.total_count}} {{typeof pgettext === "function" && pgettext ? pgettext('Label of search results found', 'results') : "results"}}</p>
                        <div class="search-result-card-container">
                            <div v-for="record in apiResponse.items" :key="record.id"
                                class="cell small-12 medium-4 large-4 search-result-card" >
                                <ResultSearchCard @close-modal="closeModal" :record="record"/>
                            </div>
                        </div>
                    </div>

                    <div v-if="search !='' && apiResponse.items && apiResponse.items.length == 0" class="search-results-container">
                        <p class="search-results-found">{{typeof pgettext === "function" && pgettext ? pgettext('Label of search results not found', 'No search results found') : "No search results found"}}</p>
                    </div>

                    <div v-if="loading || hasMoreResults" class="cell small-12 text-center load-more-wrapper">
                        <button :disabled="loading" v-on:click="moreResults()"
                                class="hollow button button-form button-white">
                            <span v-if="!loading">{{typeof pgettext === "function" && pgettext ? pgettext('Load more pagination button', 'load more') : "load more"}}</span>
                            <span v-if="loading">{{typeof pgettext === "function" && pgettext ? pgettext('Loading status on Load more button', 'loading') : "loading"}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import SearchForm from '../snippets/SearchForm'
    import ResultSearchCard from "../snippets/ResultSearchCard"

    export default {
        props: {
            page: {
                type: Object
            },
            showSearchModal: {
                type: Boolean
            }
        },
        data: function () {
            return {
                search: '',
                pageNumber: 1,
                loading: false,
                clearSearchField: false,
                apiResponse: {},
            }
        },
        components: {
            SearchForm,
            ResultSearchCard
        },
        mounted() {
            if (this.page && this.$root.$children[0].$children[0].active_id === this.page.id)
                this.$root.$children[0].$children[0].updateScrollPosition(this.$el)
        },
        computed: {
            hasMoreResults() {
                // eslint-disable-next-line no-prototype-builtins
                if (!this.apiResponse.hasOwnProperty('meta')) {
                    return false
                }
                let total = this.apiResponse.meta.total_count
                return (this.pageNumber * this.getLimit()) < total
            },
        },
        methods: {

            closeModal() {
                this.$emit('close-modal')
                this.cleanSearch()
                this.clearSearchField=true
                setTimeout(() => {
                    this.clearSearchField=false
                }, 100);
            },

            pgettext: window.pgettext,

            getLimit() {
                if (window.matchMedia('(min-width: 640px)').matches) {
                    return window.pagination_count
                }
                return window.pagination_count_mobile
            },

            getOffset() {
                return (this.pageNumber - 1) * this.getLimit()
            },

            fireSearch(search) {
                this.cleanSearch()
                this.search = search
                this.fetchResults()
            },

            getDefaultUrl() {
                return `search/?descendant_of=${window.app_home_id}&fields=*`
            },

            moreResults() {
                if (this.loading) return
                this.pageNumber++
                this.fetchResults()
            },

            async fetchResults() {
                if (this.search == '') {
                    this.cleanSearch()
                    return true
                }

                let url = this.getDefaultUrl()

                this.loading = true

                let offset = this.getOffset()
                let limit = this.getLimit()

                url = url + `&offset=${offset}&limit=${limit}`

                if (this.search != '') {
                    url = url + '&search=' + this.search
                }

                // Call server api
                const res = await axios.get(window.api_url + `${url}`).catch(errors => {
                });

                if (this.pageNumber == 1) {
                    this.apiResponse = res.data
                } else if (Object.prototype.hasOwnProperty.call(this.apiResponse, 'items')) {
                    this.apiResponse.items = this.apiResponse.items.concat(res.data.items)
                }

                this.loading = false
            },

            cleanSearch() {
                this.search = ''
                this.pageNumber = 1
                this.apiResponse = {}
            }
        },

        created() {
            this.pageNumber = 1
        }
    }
</script>