<template>
    <div class="page page-home-page" :class="{'no-interviews': page.interview_cards.length < 1}">

        <div class="page-content">
            <div :class="'home-image-header ' + (page.featured_image_always_full_width ? 'full-width' : 'restricted-width')">
                <PictureSnippet v-if="$parent.$parent.lazyload.includes(page.id)" :res="$parent.$parent.responsive_size"
                                :image="page.head_image"/>
                <BetonLogo/>
            </div>
            <!-- Added for GRID REFACTOR -->
            <!-- ------------ -->

            <div class="links-section links-section-focus" :class="page.focus_cards.length == 0 && 'no-cards'">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell hide-for-small-only medium-1"></div>
                        <h3 class="cell small-12 medium-8 large-7 homepage-subtitle">{{page.subtitle}}</h3>
                        <div class="cell hide-for-small-only medium-3 large-4"></div>

						<div class="cell hide-for-small-only large-3"  :class="page.focus_cards.length == 0 ? 'medium-3' : 'hide-for-medium-only'"></div>
						<p class="cell small-12 medium-6 large-8 homepage-lead-text" :class="this.$root.$children[0].$children[0].active_id === this.page.id && 'h-title'">{{page.lead_text}}</p>
						<div class="cell hide-for-small-only hide-for-medium-only large-1"></div>

                        <div v-for="(block, index) in page.focus_cards" v-bind:key="index"
                             class="cell home-link-section-cell small-12 medium-6 large-4 link-block focus-link-block">
                            <div v-on:click.stop.prevent="click(block.value.id)">
                                <a :id="block.value.id" :href="block.value.url" v-on:click.prevent>
                                    <PictureThumbnail v-if="$parent.$parent.lazyload.includes(page.id)"
                                                      :image="block.value.thumbnail"/>
                                    <div class="link-text">
										<span class="link-title">
											<p v-html="block.value.link_title ? block.value.link_title : block.value.title"/>
										</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <CallToAction v-if="cta_focus.related_page" :value="cta_focus"/>
                </div>
            </div>

            <div class="links-section links-section-news-and-stories">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <h3 v-if="page.news_and_stories" class="default-style cell small-12 link-section-title h4 text-left">
                            {{page.news_and_stories_title}}</h3>

                        <div v-for="(block, index) in page.news_and_stories" v-bind:key="'news_and_stories_'+index"
                             class="cell home-link-section-cell blogpost-link-section-cell small-12 medium-4 link-block fact-link-block">
                            <div class="post-card" v-on:click.stop.prevent="click(block.value.id)">
                                <a :id="block.value.id" :href="block.value.url" v-on:click.prevent>
                                    <PictureThumbnail v-if="$parent.$parent.lazyload.includes(page.id)"
                                                      :image="block.value.thumbnail"/>
                                    <div class="link-text flex-container flex-dir-column link-text-padding">
                                        <p class="link-title reset-padding"><b v-html="block.value.link_title ? block.value.link_title : block.value.title"></b></p>
                                        <p class="link-excerpt body-two" v-html="block.value.excerpt"></p>
                                        <small class="link-time text-right">{{block.value.reading_time}}</small>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <CallToAction v-if="cta_news_and_stories.related_page" :value="cta_news_and_stories"/>
                </div>
            </div>

            <div class="links-section link-section-gray links-section-facts">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell hide-for-small-only medium-1 large-2"></div>
                        <h3 v-if="page.fact_title" class="cell small-12 medium-10 large-8 link-section-title">
                            {{page.fact_title}}</h3>
                        <div class="cell hide-for-small-only medium-1 large-2"></div>

                        <div v-for="(block, index) in page.fact_cards" v-bind:key="index"
                             class="cell home-link-section-cell small-12 medium-6 large-4 link-block fact-link-block">
                            <div v-on:click.stop.prevent="click(block.value.id)">
                                <a :id="block.value.id" :href="block.value.url" v-on:click.prevent>
                                    <PictureThumbnail v-if="$parent.$parent.lazyload.includes(page.id)"
                                                      :image="block.value.thumbnail"/>
                                    <div class="link-text">
                                        <span class="h1 prefix">{{block.value.prefixNum}}</span>
                                        <span class="link-title">
											<p v-html="block.value.link_title ? block.value.link_title : block.value.title"/>
										</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <CallToAction v-if="cta_fact.related_page" :value="cta_fact"/>
                </div>
            </div>

            <div v-if="page.interview_cards.length > 2" class="links-section">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell hide-for-small-only medium-1 large-2"></div>
                        <h3 v-if="page.interview_title"
                            class="cell small-12 medium-10 large-8 link-section-title link-section-title-interview">
                            {{page.interview_title}}</h3>
                        <div class="cell hide-for-small-only medium-1 large-2"></div>
                        <div class="cell hide-for-small-only medium-1 large-2"></div>
                        <p v-if="page.interview_description"
                           class="cell small-12 medium-10 large-8 link-section-description">
                            {{page.interview_description}}</p>
                        <div class="cell hide-for-small-only medium-1 large-2"></div>

                        <div v-for="(block, index) in page.interview_cards" v-bind:key="index"
                             class="cell home-link-section-cell small-12 medium-4 large-4 link-block interview-link-block">
                            <div v-on:click.stop.prevent="click(block.value.id)">
                                <a :id="block.value.id" :href="block.value.url" v-on:click.prevent>
                                    <PictureThumbnail v-if="$parent.$parent.lazyload.includes(page.id)"
                                                      :image="block.value.thumbnail"/>
                                    <div class="link-text">
										<span class="link-title">
											<p>{{block.value.interviewed_surname}} {{block.value.interviewed_name}}</p>
											<p>{{block.value.interviewed_position}}</p>
										</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <CallToAction v-if="cta_interview.related_page" :value="cta_interview"/>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import axios from 'axios'
    import CallToAction from '../blocks/CallToAction';
    import PictureThumbnail from '../snippets/PictureThumbnail'
    import PictureSnippet from '../snippets/PictureSnippet'
    import BetonLogo from '../icons/BetonLogo'

    export default {
        props: {
            page: {
                type: Object
            },
        },
        data: function () {
            return {
                pgettext: window.pgettext,
                mediaURLPrefix: window.mediaURLPrefix,
                cta_fact: {},
                cta_focus: {},
                cta_interview: {},
                cta_news_and_stories: {}
            }
        },
        components: {
            CallToAction,
            PictureThumbnail,
            PictureSnippet,
            BetonLogo
        },
        methods: {
            getUrl(id) {
                let page = this.$parent.$parent.json_data.find(o => o.id === id);
                return page.url;
            },
            click(page_id) {
                this.$parent.$parent.goTo({id: page_id, mode: "link"})
            },
            loadRelatedPosts() {
                let endpoints = [];
                this.page.news_and_stories.forEach(post => {
                    let id = post.value.id
                    let findPage = this.$parent.$parent.json_data.find(o => o.id === id);
                    if(!findPage) {
                        endpoints.push(window.api_url + `blog/${id}/`)
                    }
                })
                axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
                    data => {
                        data.forEach(response => {
                            this.$parent.$parent.json_data.push(response.data)
                        })
                    }
                );
            }
        },
        created() {
            this.loadRelatedPosts()
            this.cta_focus.label = this.page.focus_cta_label
            this.cta_focus.label_small = this.page.focus_cta_label_small
            this.cta_focus.slug = this.page.focus_cta_slug
            this.cta_focus.related_page = this.page.focus_cta_related_page

            this.cta_fact.label = this.page.fact_cta_label
            this.cta_fact.label_small = this.page.fact_cta_label_small
            this.cta_fact.slug = this.page.fact_cta_slug
            this.cta_fact.related_page = this.page.fact_cta_related_page

            this.cta_interview.label = this.page.interview_cta_label
            this.cta_interview.label_small = this.page.interview_cta_label_small
            this.cta_interview.slug = this.page.interview_cta_slug
            this.cta_interview.related_page = this.page.interview_cta_related_page

            this.cta_news_and_stories.label = this.page.news_and_stories_cta_label
            this.cta_news_and_stories.label_small = this.page.news_and_stories_cta_label_small
            this.cta_news_and_stories.related_page =  this.page.news_and_stories_cta_related_page
        },
        mounted() {
            if (this.$root.$children[0].$children[0].active_id === this.page.id)
                this.$root.$children[0].$children[0].updateScrollPosition(this.$el)

        }
    }
</script>