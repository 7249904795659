<template>
    <div class="module module-image-slider-scroll">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a> 
        <!-- <div class="grid-container fluid slider-wrapper" @touchstart.stop @mousedown.stop> -->
        <div class="grid-container fluid slider-wrapper" @touchstart.stop @mousedown.stop>
            <overlay-scrollbars
                :options="{ 
                    className:'slider os-theme-dark',
                    scrollbars: { clickScrolling: true },
                    overflowBehavior: { x : 'scroll', y : 'hidden'},
                    callbacks: {
                        // Dispatch scroll main event once scrolling so Lazyload can do its job
                        onScroll: overlayScrolled
                        }
                    }">              
                    <div v-for="(slide, index) in value.slides" :key="slide.value.image.title + ' - ' + index">
                        <PictureSliderScroll v-if="slide.value.image.title && $parent.$parent.$parent.$parent.lazyload.includes($parent.pageid)" :res="$parent.$parent.$parent.$parent.responsive_size" :alt="slide.value.image.image_alt"  :image="slide.value.image" :caption="slide.value.caption" :number="(index+1)+'/'+value.slides.length" />
                    </div>
            </overlay-scrollbars>                
        </div>
    </div>
</template>

<script>
    import PictureSliderScroll from '../snippets/PictureSliderScroll'

    export default {
        props: {
            value: {
                type: Object
            }
        },
		data: function () {
			return {}
		},
        methods: {
            // this function runs at the reload of the page or when the user first lands on it through an external method
            // It reads the url on the browser to know which page it should render to the user
            overlayScrolled(){
                window.dispatchEvent(new CustomEvent('scroll'))
            }
        },        
        components: {
            PictureSliderScroll,
        }
    }
</script>