var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module module-image-slider-scroll"},[(_vm.value.slug)?_c('a',{staticClass:"module-anchor-link",attrs:{"id":[_vm.value.slug]}}):_vm._e(),_c('div',{staticClass:"grid-container fluid slider-wrapper",on:{"touchstart":function($event){$event.stopPropagation();},"mousedown":function($event){$event.stopPropagation();}}},[_c('overlay-scrollbars',{attrs:{"options":{ 
                className:'slider os-theme-dark',
                scrollbars: { clickScrolling: true },
                overflowBehavior: { x : 'scroll', y : 'hidden'},
                callbacks: {
                    // Dispatch scroll main event once scrolling so Lazyload can do its job
                    onScroll: _vm.overlayScrolled
                    }
                }}},_vm._l((_vm.value.slides),function(slide,index){return _c('div',{key:slide.value.image.title + ' - ' + index},[(slide.value.image.title && _vm.$parent.$parent.$parent.$parent.lazyload.includes(_vm.$parent.pageid))?_c('PictureSliderScroll',{attrs:{"res":_vm.$parent.$parent.$parent.$parent.responsive_size,"alt":slide.value.image.image_alt,"image":slide.value.image,"caption":slide.value.caption,"number":(index+1)+'/'+_vm.value.slides.length}}):_vm._e()],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }