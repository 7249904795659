<template>
	<div class="page contact-page">

		<div class="page-content">
			<Blocks :pageid="page.id" :body="page.body" :data="$parent.$parent.json_data" @goTo="$parent.goTo" />

			<Form :token="token" :page="page" :data="form_data" />

		</div>

	</div>
</template>

<script>
    import Blocks from '../Blocks'
	import Form from '../snippets/Form.vue'

	export default {
		props: {
			page: {
				type: Object
			},
			token: {
				type: String
			}
		},
		data: function () {
			return { 
				pgettext: window.pgettext,
				mediaURLPrefix: window.mediaURLPrefix,
				form_data: {}
			}
		},
		components: {
			Blocks,
			Form,
		},
		methods: {
			getUrl(id) {
                let page = this.$parent.$parent.pages.find(o => o.id === id);
                return page.url;
			},
		},
		created(){
			this.form_data = this.$parent.$parent.contact_form_data;
		},
		mounted() {
			if (this.$root.$children[0].$children[0].active_id === this.page.id) 
				this.$root.$children[0].$children[0].updateScrollPosition(this.$el)

		}
	}
</script>