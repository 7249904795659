<template>
    <div class="app-menu" :class="{'open': show}">
        <div class="drawer-topbar">
            <div class="grid-container">
                <LanguageSwitcher :on_drawer="true" :show_lang_switcher="$parent.show_lang_switcher"
                                  :showOnHome="$parent.active_id === $parent.home_id" :language="$parent.language"
                                  :active="$parent.active_id"/>
                <Search @open-modal="openModal"/>
                <button class="button button-menu button-menu-close" v-on:click="$parent.menuOpen = !$parent.menuOpen">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17 1.7L15.3 0L8.5 6.8L1.7 0L0 1.7L6.8 8.5L0 15.3L1.7 17L8.5 10.2L15.3 17L17 15.3L10.2 8.5L17 1.7Z"
                              fill="#2C2C2C"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="menu-close-overlay" v-on:click="$parent.menuOpen = false"/>
        <div class="app-menu-content">
            <div class="grid-container drawer-content">

                <div v-if="links.blogIndexPage && links.blogIndexPage.meta.show_in_menus" class="grid-x menu-links-container">
                    <div class="cell small-12 large-2 large-offset-6 menu-section-title">{{typeof pgettext ===
                        "function" && pgettext ? pgettext('Drawer Menu "Latest" Section title', 'Latest') :
                        "Latest"}}
                    </div>
                    <div class="cell small-12 large-4 menu-links">
                        <a v-bind:key="links.blogIndexPage.id" :id="links.blogIndexPage.id"
                           :href="links.blogIndexPage.url" v-on:click.stop.prevent="click(links.blogIndexPage.id)" :tabindex="tabindex">
                            <span>{{links.blogIndexPage.menu_title ? links.blogIndexPage.menu_title : links.blogIndexPage.title}}</span>
                        </a>
                    </div>
                </div>

                <div v-if="show_focus_in_menus" class="grid-x menu-links-container">
                    <div class="cell small-12 large-2 large-offset-6 menu-section-title">{{typeof pgettext ===
                        "function" && pgettext ? pgettext('Drawer Menu "Fokus" Section title', 'Focus') : "Focus"}}
                    </div>
                    <div class="cell small-12 large-4 menu-links">
                        <a v-for="page in links.focus" v-bind:key="page.id" :id="page.id" :href="page.url"
                           v-on:click.stop.prevent="click(page.id)" :tabindex="tabindex">
                            <span>{{page.menu_title ? page.menu_title : page.title}}</span>
                        </a>
                    </div>
                </div>

                <div v-if="show_facts_in_menus" class="grid-x menu-links-container">
                    <div class="cell small-12 large-2 large-offset-6 menu-section-title">{{typeof pgettext ===
                        "function" && pgettext ? pgettext('Drawer Menu "Fakten" Section title', 'Facts') : "Facts"}}
                    </div>
                    <div class="cell small-12 large-4 menu-links">
                        <a v-for="page in links.facts" v-bind:key="page.id" :id="page.id" :href="page.url"
                           v-on:click.stop.prevent="click(page.id)" :tabindex="tabindex">
                            <span>{{page.prefixNum}} {{page.menu_title ? page.menu_title : page.title}}</span>
                        </a>
                    </div>
                </div>

                <div v-if="show_other_in_menus" class="grid-x menu-links-container">
                    <div class="cell small-12 large-4 large-offset-8 menu-links">
                        <a v-for="page in links.other" v-bind:key="page.id" :id="page.id" :href="page.url"
                           v-on:click.stop.prevent="click(page.id)" :tabindex="tabindex">
                            <span>{{page.title}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LanguageSwitcher from './snippets/LanguageSwitcher'
    import Search from "./Search";

    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },
            goTo: {
                type: Function
            },
        },
        components: {
            LanguageSwitcher,
			Search
        },
        data: function () {
            return {
                json_data: this.$parent.json_data,
                links: {blogIndexPage: null, focus: [], facts: [], other: []},
                facts_desktop: [],
                links_desktop: [],
                home_id: window.app_home_id,
                mediaURLPrefix: window.mediaURLPrefix,
                pgettext: window.pgettext,
                tabindex: -1
            }
        },
        computed: {
            show_focus_in_menus() {
                return this.links.focus.length > 0
            },

            show_facts_in_menus() {
                return this.links.facts.length > 0
            },

            show_other_in_menus() {
                return this.links.other.length > 0
            },
        },
        methods: {
            // Function linked to Base's goTo to navigate inside the site
            openModal() {
                this.$emit("open-modal");
            },

            click(page_id) {
                this.goTo({id: page_id, mode: "link"})
                this.$parent.menuOpen = false;
            },
        },
        created() {
            let json_data = this.$parent.$parent.json_data;
            let links = this.links;

            if (this.$parent.$parent.blogIndexPage) {
                this.links.blogIndexPage = this.$parent.$parent.blogIndexPage
            }
            
            let othersTypes = ["app_headless.AppGenericPage", "app_headless.AppDownloadsPage", "app_headless.AppFAQPage", "app_headless.AppContactPage"]

            json_data.forEach(page => {
                if (page.meta.type == 'app_headless.AppFocusPage' && page.meta.show_in_menus) {
                    links.focus.push(page)
                }

                if (page.meta.type == 'app_headless.AppFactPage' && page.meta.show_in_menus) {
                    links.facts.push(page)
                }

                if (othersTypes.includes(page.meta.type) && page.meta.show_in_menus){
                    links.other.push(page)
                }
            })

            this.links = links;
        },
        watch: {
            // Refresh data on prop change | update tabindex
            $props: {
                handler: function () {
                    this.show ? this.tabindex = 0 : this.tabindex = -1
                },
                immediate: true,
                deep: true
            },
        }
    }
</script>