<template>
    <div class="module module-text">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a>
        <div class="grid-container grid-x">
            <div class="cell small-12 medium-10 large-8">
                <div v-on:click="click($event)" v-html="text" />
            </div>
        </div>

    </div>
</template>

<script>
    import processAnchors from '../../scripts/replaceInternalAnchors';

    export default {
        props: {
            value: {
                type: Object
            }
        },
        methods: {
            click(event){
                let target = event.target;
                if(target.id){
                    event.preventDefault();
                    event.stopPropagation();
                    this.$parent.$emit('goTo', {id: target.id, mode: "link", customHash: target.attributes.slug ? target.attributes.slug.value : false})
                }
            }
        },
        data: function () {
            return{
                text: ""
            }
        },
        created(){
            // Replace all anchors with a page id so they have href and a link can be shared
            this.text = processAnchors(this.value.text, this.$parent.data)
        }
    }
</script>
