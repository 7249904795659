<template>
    <div class="module module-teaser" :class="[{['themed-'+value.theme]: value.theme}, {[value.size]: value.size}]">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a>         
        <div class="grid-container grid-x quote">
            <div class="cell small-12 medium-10 large-8">
                <p class="teaser-title">{{ value.title }}</p>

                <p class="teaser-text">{{ value.text }}</p>

                <a v-if="value.button_url" :id="value.page_id" :href="value.button_url" :linktype="value.link_type" :target="value.button_target" class="hollow button button-interview button-white button-teaser" v-on:click="click(value, $event)">
                    <span>{{value.button_label}}</span>
                </a> 
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Object
            }
        },
        methods: {
            click(object, event){
                let target = event.target;
                if(target.id) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.$parent.$emit('goTo', {id: object.page_id, mode: "link", customHash: object.slug ? object.slug : false})
                }
            }
        },        
    }
</script>
