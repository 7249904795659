<template>
    <div class="slide">
        <figure class="slide-image">
            <picture>
                <!-- <img :alt="image.title" :src="mediaURLPrefix+curr_image.url" :width="curr_image.width" :height="curr_image.height" /> -->
                <img :alt="alt" v-lazy="mediaURLPrefix+curr_image.url" :width="curr_image.width" :height="curr_image.height" />
            </picture>
            <div class="picture-text">
                <span class="slide-image-number hide-for-medium">{{number}}</span>
                <figcaption v-if="caption" v-html="caption" />
            </div>
        </figure>
    </div>

</template>

<script>
    export default {
        props: {
            image: {
                type: Object
            },
            number: {
                type: String
            },
            caption: {
                type: String
            },
            alt: {
                type: String
            },
            res: {
                type: String
            }
        },
        data: function () {
            return{
                mediaURLPrefix: window.mediaURLPrefix,
                curr_image: {}
            }
        },
		watch: { 
			// Refresh data on prop change | update tabindex
			$props: {
				handler: function() {
                    if (this.res === "small") {
                        this.curr_image = this.image.renditions.small
                    }else{
                        this.curr_image = this.image.renditions.large
                    }
				},
				immediate: true,
				deep: true
			},
		}
    }
</script>