<template>
	<div class="page interview-page">
		
		<SharingLinks :page="page" />
		<div class="page-content">

			<div class="grid-container grid-x">
				<div class="cell small-12 medium-10 large-8">
					<a :id="prev_page.id" :href="prev_page.url" class="hollow button button-back button-white" v-on:click.stop.prevent="click(prev_page)">
						<svg width="34" height="22" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 11L31.111 11" stroke="#2C2C2C" stroke-width="2.5" stroke-miterlimit="10"/>
							<path d="M22.2199 21.0009L31.6687 11.0008L22.2215 1.00085" stroke="#2C2C2C" stroke-width="2.5"/>
						</svg>
						<span>{{(typeof pgettext === "function" && pgettext ? pgettext('Interview Page - Back button', 'Zurück') : "Zurück").toUpperCase()}}</span>
					</a>
				</div>
			</div>

			<div class="grid-container grid-	x">
				<div class="cell small-12 medium-10 large-8">
					<h3 class="page-title">{{ page.title }}</h3>
				</div>
			</div>


			<div class="grid-container grid-x section-featured-image">
				<div class="cell small-12 medium-10 large-8">
					<p v-if="page.reading_time" class="reading-time">{{page.reading_time}}</p>
					<SharingLinks :page="page" />
				</div>
			</div>
			<div class="grid-container grid-x section-featured-image-picture">
				<div class="cell small-12 medium-12 large-10 margin-auto">
					<PictureSnippet v-if="$parent.$parent.lazyload.includes(page.id)" :res="$parent.$parent.responsive_size" :image="page.head_image" :alt="page.featured_image_alt" />
				</div>
			</div>
			<!-- Added this figcaption outside of the featured image to respect grid columns required -->
			<div class="grid-container grid-x special-figcaption">
				<div class="cell small-12 medium-10 large-8">
					<p class="caption" v-if="page.featured_image_caption">{{page.featured_image_caption}}</p>
				</div>
			</div>

			<div v-if="page.lead_text" class="grid-container grid-x lead-text" :class="this.$root.$children[0].$children[0].active_id === this.page.id && 'h-title'">
				<div class="cell small-12 medium-10 large-8" v-html="page.lead_text" />
			</div>
			<Blocks :pageid="page.id" :body="page.body" :data="$parent.$parent.json_data" @goTo="$parent.goTo" />

			<div class="grid-container grid-x">
				<div class="cell small-12 medium-10 large-8">
					<a :id="prev_page.id" :href="prev_page.url" class="hollow button button-back button-white page-end" v-on:click.stop.prevent="click(prev_page)">
						<svg width="34" height="22" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 11L31.111 11" stroke="#2C2C2C" stroke-width="2.5" stroke-miterlimit="10"/>
							<path d="M22.2199 21.0009L31.6687 11.0008L22.2215 1.00085" stroke="#2C2C2C" stroke-width="2.5"/>
						</svg>
						<span>{{(typeof pgettext === "function" && pgettext ? pgettext('Interview Page - Back button', 'Zurück') : "Zurück").toUpperCase()}}</span>
					</a>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
    import Blocks from '../Blocks'
	import PictureSnippet from '../snippets/PictureSnippet'
	import SharingLinks from '../snippets/SharingLinks'
	
	export default {
		props: {
			page: {
				type: Object
			},
			prev: {
				type: Number
			}
		},
		data: function () {
			return {
				prev_page: {},
				pgettext: window.pgettext,
				mediaURLPrefix: window.mediaURLPrefix,
			}
		},
		components: {
			Blocks,
			PictureSnippet,
			SharingLinks
		},
		methods: {
			// Function linked to Base's goTo to navigate inside the site
            click(page){
				this.$parent.$parent.goTo({id: page.id, mode: "prev"})
			}
		},
		watch: { 
			// Refresh data on prop change | update prev page data, so "back" button points to last page
			$props: {
				handler: function() {
					let id = this.prev;
					if(!id || id === 0) id = this.$parent.$parent.home_id;
					let page = this.$parent.$parent.json_data.find(o => o.id === id);
					if(page.meta.type === "app_headless.AppInterviewPage") return;
					this.prev_page = {
						id: page.id,
						href: page.url
					}
				},
				immediate: true,
				deep: true
			},
		},
		mounted() {
			if (this.$root.$children[0].$children[0].active_id === this.page.id) 
				this.$root.$children[0].$children[0].updateScrollPosition(this.$el)

		}
	}
</script>