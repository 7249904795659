<template>
    <div class="module module-image-text">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a>         
        <div class="grid-container">
            <div :class="'grid-x grid-margin-x align-center ' + (value.image_position == 'right' ? 'flex-dir-row-reverse' : '')">
                <div :class="`cell small-12 medium-10 large-` + left_column_large_cols">
                    <Picture43or169Snippet :image="value.image" :alt="value.image_alt" :caption="value.image_caption" />
                </div>
                <div :class="`cell small-12 medium-10 large-` + right_column_large_cols">
                    <div class="text" v-on:click="click($event)" v-html="text" />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import processAnchors from '../../scripts/replaceInternalAnchors';
    import Picture43or169Snippet from '../snippets/Picture43or169Snippet'

    export default {
        props: {
            value: {
                type: Object
            }
        },
        methods: {
            // Function linked to Base's goTo to navigate inside the site
            click(event){
                let target = event.target;
                if(target.id) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.$parent.$emit('goTo', {id: target.id, mode: "link", customHash: target.attributes.slug ? target.attributes.slug.value : false})
                }
            }
        },
            data: function(){
                return{
                    text: "",
                    left_column_large_cols:5,
                    right_column_large_cols:5,
                }
            },
        components: {
            Picture43or169Snippet
        },
        created(){
            this.text = processAnchors(this.value.text, this.$parent.data);

            switch (true) {
                case this.value.image_size=='big':
                    this.left_column_large_cols=7;
                    this.right_column_large_cols=3;
                    break;
                case this.value.image_size=='small':
                    this.left_column_large_cols=3;
                    this.right_column_large_cols=7;
                    break;
                default:
                    this.left_column_large_cols=5;
                    this.right_column_large_cols=5;
                    break;
            }
        }
    }
</script>
