<template>
    <div class="module module-quote" :class="{['themed-'+value.theme]: value.theme}">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a>         
        <div class="grid-container grid-x quote">
            <div class="cell small-12 medium-10 large-8">
                <blockquote>{{value.quote}}</blockquote>
                <p v-if="value.author" class="caption">{{value.author}}</p>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Object
            }
        }
    }
</script>
