// Add href to all anchors inside text that only have a page id so their link can be shared and open on a new page
export default function processAnchors(text, data){
    let snippetAnchors = text.matchAll(RegExp('<*a id="(.*?)" *>','g'));
    // Try to replace page links
    for(const match of snippetAnchors){        
         if (match[0].includes('linktype="page"')){
            let missingHref= match[0].matchAll(RegExp(' id="(.*?)" linktype="page"','g'))
            for(const matchHref of missingHref){
                let page = data.find(o => o.id === parseInt(matchHref[1]));
                if(page) text = text.replace(matchHref[0], `${matchHref[0]} href="${page.url}"`)
            }
        }
    }

    // Try to replace absolute links
    let missingId = text.matchAll(RegExp('<a href="(.*?)"','g'));
    for(const match of missingId){
        let page = data.find(o => o.url === match[1]);
        if(page) text = text.replace(match[0], `${match[0]} id="${page.id}"`)
    }

    // find all instances of external links and add target blank
    const regexp = RegExp('<a href(.*?)>','g');
    let matches = text.matchAll(regexp);

    for(const match of matches){
        if(!match[0].includes('linktype="page"') && !match[0].includes('linktype="snippet"')) {
            let replaced = match[0].replace('>', ' target="_blank" rel="noopener noreferrer">');
            text = text.replace(match[0], replaced);
        }
    }

    return text;
}
