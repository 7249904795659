<template>
    <div class="module module-two-images">
                
        <div class="grid-container">
            <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a> 
            <div class="grid-x grid-margin-x grid-margin-y align-center">
                <div class="cell small-12 medium-6 large-5">
                    <PictureRichSnippet v-if="$parent.$parent.$parent.$parent.lazyload.includes($parent.pageid)" :res="$parent.$parent.$parent.$parent.responsive_size" :alt="value.image_one.alt" :image="value.image_one" :caption="value.image_one_caption" />
                </div>
                <div class="cell small-12 medium-6 large-5">
                    <PictureRichSnippet v-if="$parent.$parent.$parent.$parent.lazyload.includes($parent.pageid)" :res="$parent.$parent.$parent.$parent.responsive_size" :alt="value.image_two.alt" :image="value.image_two" :caption="value.image_two_caption" />
                </div>
            </div>
        </div>    

    </div>
</template>

<script>
    import PictureRichSnippet from '../snippets/PictureRichSnippet'

    export default {
        props: {
            value: {
                type: Object
            }
        },
        components: {
            PictureRichSnippet
        }
    }
</script>