<template>
    <div v-if="page" class="module module-call-to-action">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a> 
        <a :id="page.id" :href="page.url" class="button secondary button-call-to-action cell small-12 medium-8 large-6" v-on:click="click(page, $event)">
        <!-- <a :id="page.id" :href="page.url+(value.slug ? '#'+value.slug : '')" class="button secondary button-call-to-action cell small-12 medium-8 large-6" v-on:click="click($event.target)"> -->
            <span v-if="value.label_small" class="cta-label-small">{{value.label_small}}</span>
            <span v-if="value.label">{{value.label}}</span>
            <span v-if="value.button_label">{{value.button_label}}</span>
            <svg width="34" height="22" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 11L31.111 11" stroke="#2C2C2C" stroke-width="2.5" stroke-miterlimit="10"/>
                <path d="M22.2199 21.0009L31.6687 11.0008L22.2215 1.00085" stroke="#2C2C2C" stroke-width="2.5"/>
            </svg>
        </a>

    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Object
            },
        },
		data: function () {
			return{
				page: {},
			}
		},
        methods: {
			// Function linked to Base's goTo to navigate inside the site
            click(page, event){
                let target = event.target;
                if(target.id) {
                    event.preventDefault();
                    event.stopPropagation();
                    if(this.value.button_label) this.$parent.$parent.$parent.$parent.goTo({id: page.id, mode: "link"});
                    else this.$parent.$parent.goTo({id: page.id, mode: "link"});
                }
            }
        },
        created(){
            let tested_page;
            if(this.value.related_page.id){
                tested_page = this.$parent.$parent.$parent.$parent.json_data.find(o => o.id === this.value.related_page.id)
            } else {
                tested_page = this.$parent.$parent.$parent.$parent.json_data.find(o => o.id === this.value.related_page)
            }
            this.page = tested_page ? tested_page : false;
        }
    }
</script>
