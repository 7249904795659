<template>

    <figure v-if="initial_image">
        <picture v-lazy-container="{ selector: 'img' }">
            <img :alt="alt" :data-src="curr_image.url.startsWith('http') ? curr_image.url : mediaURLPrefix+curr_image.url" :width="curr_image.width" :height="curr_image.height" :data-loading="initial_image.renditions ? (this.res === 'small' ? mediaURLPrefix+image.renditions.small.url : mediaURLPrefix+loading_image) : loading.url" />
        </picture>
        <div v-if="caption">
            <figcaption v-if="boxed_caption" class="grid-x" :class="{'grid-container': size == 'fullwidth'}">
                <span class="cell small-12 medium-10 large-10 margin-auto">
                    {{ caption }}
                </span>
            </figcaption>
            <figcaption v-else :class="{'grid-container': size == 'fullwidth'}">{{ caption }}</figcaption>
        </div>
    </figure>

</template>

<script>
    export default {
        props: {
            image: {
                type: Object
            },
            caption: {
                type: String
            },
            loading: {
                type: Object
            },
            res: {
                type: String
            },
            alt: {
                type: String
            },
            boxed_caption:{
                type: Boolean
            }
        },
        data: function () {
            return{
                loading_image: "",
                mediaURLPrefix: window.mediaURLPrefix,
                curr_image: {},
                initial_image:{}
            }
        },
		created: function(){
            if(!this.image){
                this.initial_image = {
                    title: '',
                    renditions: {
                        large: {'url': 'https://via.placeholder.com/1600x900.png?text=No+Image', 'width': 1600, 'height': 900},
                        medium: {'url': 'https://via.placeholder.com/1600x900.png?text=No+Image', 'width': 1600, 'height': 900},
                        small: {'url': 'https://via.placeholder.com/800x600.png?text=No+Image', 'width': 800, 'height': 600},
                        small_large: {'url': 'https://via.placeholder.com/800x450.png?text=No+Image', 'width': 800, 'height': 450},
                    }
                }
            } else {
                this.initial_image=this.image
            }
        },
		watch: { 
			// Refresh data on prop change | update tabindex
			$props: {
				handler: function() {
                    if(this.image.renditions){
                        if (this.res === "large") {
                            this.curr_image = this.image.renditions.large
                            this.loading_image = this.image.renditions.small_large ? this.image.renditions.small_large.url : this.image.renditions.small.url;
                        } else if (this.res === "medium") {
                            this.curr_image = this.image.renditions.medium
                            this.loading_image = this.image.renditions.small_large ? this.image.renditions.small_large.url : this.image.renditions.small.url;
                        }else{
                            this.curr_image = this.image.renditions.small
                        }
                    }else{
                        this.curr_image = this.image
                    }
				},
				immediate: true,
				deep: true
			},
		}
    }
</script>