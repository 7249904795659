<template>
    <div v-on:click="openModal()" class="search-icon show-on-tablet">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0262 12.8476L17.5953 16.4159L16.4162 17.5951L12.8478 14.0259C11.5201 15.0903 9.86866 15.6692 8.16699 15.6667C4.02699 15.6667 0.666992 12.3067 0.666992 8.16675C0.666992 4.02675 4.02699 0.666748 8.16699 0.666748C12.307 0.666748 15.667 4.02675 15.667 8.16675C15.6694 9.86841 15.0905 11.5199 14.0262 12.8476ZM12.3545 12.2292C13.4121 11.1417 14.0027 9.68377 14.0003 8.16675C14.0003 4.94341 11.3895 2.33341 8.16699 2.33341C4.94366 2.33341 2.33366 4.94341 2.33366 8.16675C2.33366 11.3892 4.94366 14.0001 8.16699 14.0001C9.68402 14.0025 11.1419 13.4118 12.2295 12.3542L12.3545 12.2292Z" fill="#2C2C2C"/>
        </svg>
    </div>

</template>

<script>
    export default {
        props: {
            show_search_modal: Boolean
        },
        data: function () {
            return {}
        },
        methods: {
            openModal() {
                this.$emit("open-modal", true);
            }
        },
    }
</script>