<template>
    <div class="module module-interview-teaser">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a> 
        <div class="teaser-mobile" :class="'themed-'+value.theme">
            <div class="grid-container">
                <h4>{{value.title}}</h4>
                <p>{{value.subtitle}}</p>
            </div>
            <PictureSnippet v-if="value.image.title && $parent.$parent.$parent.$parent.lazyload.includes($parent.pageid)" :alt="value.image.image_alt" :image="value.image" :loading="value.image.renditions.small" />
        
            <div class="grid-container">
                <div class="quote">
                    <blockquote>{{value.quote}}</blockquote>
                </div>

                <a v-if="value.related_page.id" :id="value.related_page.id" :href="value.related_page.url" class="hollow button button-interview button-white" v-on:click="click(value, $event)">
                    <span>{{value.button_label}}</span>
                </a>
            </div>
        </div>
        <div class="grid-container grid-x teaser-large">
            <div class="cell large-12 grid-x">
                <div class="cell image-bg-interview small-12 medium-6" :style="imgStyle">
                </div>
                <div class="cell small-12 medium-6" :class="'themed-'+value.theme">
                    <h4>{{value.title}}</h4>
                    <p>{{value.subtitle}}</p>

                    <div class="quote">
                        <blockquote>{{value.quote}}</blockquote>
                    </div>

                    <a :id="value.related_page.id" :href="value.related_page.url" class="hollow button button-interview button-white" v-on:click="click(value, $event)">
                        <span>{{value.button_label}}</span>
                    </a>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import PictureSnippet from '../snippets/PictureSnippet'

    export default {
        props: {
            value: {
                type: Object
            }
        },
        components: {
            PictureSnippet
        },
        data: function(){
            return{
                curr_image: this.value.image.renditions.medium,
                mediaURLPrefix: window.mediaURLPrefix,
                imgStyle: {}
            }
        },
        methods: {
            click(value, event){
                let target = event.target;
                if(target.id) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.$parent.$emit('goTo', {
                        id: value.related_page.id,
                        mode: "link",
                        customHash: value.slug ? value.slug : false
                    })
                }
            },
            handleResize(){
                if (window.matchMedia('(min-width: 1440px)').matches) {
                    this.curr_image = this.value.image.renditions.large
                }else{
                    this.curr_image = this.value.image.renditions.medium
                }
                this.imgStyle = {
                    backgroundImage: `url(${this.curr_image.url.startsWith('http') ? this.curr_image.url : this.mediaURLPrefix+this.curr_image.url})`
                }
            }
        },
        created(){
            if (window.matchMedia('(min-width: 1440px)').matches) {
                this.curr_image = this.value.image.renditions.large
            }else{
                this.curr_image = this.value.image.renditions.medium
            }
            this.imgStyle = {
                backgroundImage: `url(${this.curr_image.url.startsWith('http') ? this.curr_image.url : this.mediaURLPrefix+this.curr_image.url})`
            }
            window.addEventListener('resize', this.handleResize);
        },
        beforeDestroy(){
            window.removeEventListener('resize', this.handleResize)
        }
    }
</script>
