<template>
	<div id="app-headless" :class="{'loaded': ready}">
		<Base v-if="ready" />
		<div class="app-error" v-else-if="fail">
			<div data-alert class="alert-box alert radius">
				{{typeof pgettext === "function" && pgettext ? pgettext('Page Failed to load - Text indicating error', 'There was an error loading the page') : "There was an error loading the page"}}
			</div>
			<button class="button secondary" v-on:click="reloadPage">
				{{typeof pgettext === "function" && pgettext ? pgettext('Page Failed to load - Reload button label', 'Reload') : "Reload"}}
			</button>
		</div>
	</div>
</template>

<script>
	import Base from './components/Base.vue'
	import axios from 'axios'
	import Cookies from 'js-cookie'
	import * as Sentry from "@sentry/vue";
	// import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue';

	window.mediaURLPrefix = window.mediaURLPrefix ? window.mediaURLPrefix : (window.location.origin.includes("localhost") ? window.location.origin : "");

	window.breakApp = () => {
		axios.get(window.api_url + `pages/?type=app_headless.hello&id=none&fields=*`).catch(errors => {
			Sentry.setExtra('error', errors);
			Sentry.captureException(errors);
		})
	}

	if(!(window.api_url.indexOf('http://localhost:8000')!=-1)){
		axios.defaults.headers = {
			'Cache-Control': 'no-cache',
			'Pragma': 'no-cache',
			'Expires': '0',
		};
	}

	export default {
		name: 'BetonSuisse',
		data: function () {
			return {
				ready: false,
				fail: false,
				json_data: [],
				fact_order: [],
				fact_counter: 0,
				interview_order: [],
				focus_order: [],
				blogIndexPage: null,
				footer_data: window.app_footer_data,
				contact_form_data: window.app_contact_form_data,
				social_channels_data: window.app_social_channels_data,
				home: "/",
				home_loaded: false,
				home_temp: {},
				page_types_loaded: 0,
				page_types_total: window.app_page_types.length,
				csrftoken: "",
				// pgettext: window.pgettext
			}
		},
		components: { Base },
		methods: {
			// get the entire site's data
			pgettext(context, stringToTranslate) {
				return stringToTranslate;
			},
			interpolate(fmt, obj, named) {
				if (named) {
					return fmt.replace(/%\(\w+\)s/g, function(match){return String(obj[match.slice(2,-2)])});
				} else {
					return fmt.replace(/%s/g, function(match){return String(obj.shift())});
				}
			},
			test_preview() {
				if (window.page_preview && Object.keys(window.page_preview).length > 0) {
					this.json_data.forEach((page,i) => {
						if (page.id==window.page_preview.id) {
							this.json_data.splice(i,1)	
						}
					})
					this.json_data.push(window.page_preview)
				}
			},
			// This is where everything begins
			async getData() {
				this.csrftoken = Cookies.get('csrftoken');
				const response = await axios
					.get(
						window.api_url + `pages/?type=${window.app_home_type}&id=${window.app_home_id}&fields=*`
					).then(get_response => {
						return get_response.data
					}).then(data => {
						// console.log(data);
						if (window.page_preview && Object.keys(window.page_preview).length > 0 && window.app_home_id==window.page_preview.id) {
							this.setHome(window.page_preview)
						} else {
							this.setHome(data.items[0])
						}

						if(window.app_page_types.length > 0) {
							axios.all(this.getPageRequestList()).then(axios.spread((...responses) => {
								for (let i = 0; i < responses.length; i++) {
									this.setPage(responses[i], i)
								}
								// The following line validtes the single home page object againts the other published pages, if they exits then set them as tiles as necessary
								this.setHomeTitles()
								this.ready = true
							})).catch(errors => {
								console.log("An error ocurred")
								console.log(errors)
								Sentry.captureException(errors);						
								this.fail = true
							})
						} else {
							this.ready = true
						}
					}).catch(errors => {
						console.log("An error ocurred")
						console.log(errors)
						Sentry.captureException(errors);
						this.fail = true
					})
				
			},
			// This created an array with a list of axios calls and returns it
			getPageRequestList() {
				let list = []
				for (let i = 0; i < window.app_page_types.length; i++) {
					list.push(axios.get(window.api_url + `pages/?type=${window.app_page_types[i]}&descendant_of=${window.app_home_id}&fields=*`))
				}
				return list
			},
			// take the data received via axios calls and store it in an object
			setPage(response, i) {
				response.data.items.forEach(page => {
					if(page.canonical_page){
						page.translations[window.default_language] = page.canonical_page[window.default_language]
					}
					// add prefix number to fact pages and bold title
					if(window.app_page_types[i] === "app_headless.AppFactPage"){
						let page_fact_order = this.fact_order.find(o => o.id === page.id);
						if(page_fact_order){
							page.prefixNum = page_fact_order.prefixNum;
						}else {
							this.fact_counter++;
							page.prefixNum = this.fact_counter.toString().length > 1 ? this.fact_counter.toString() : '0' + this.fact_counter.toString();
						}
					}
					if(window.app_page_types[i] === "app_blog.BlogIndexPage"){
						this.blogIndexPage = page
					}
					// Here is where all pages are pushed to the "store"
					this.json_data.push(page)
				});
			},
			// The following link sets the home page object
			setHome(home_page_response) {
				let home_page = home_page_response;

				// Arrange fact pages
				home_page.fact_cards.forEach(block => {
					if(block.value){
						// save fact order and prefix numbers to an array for later use in menus and ordering of pages
						if(!this.fact_order.find(o => o.id === block.value.id)) {
							this.fact_counter++;
							block.value['prefixNum'] = this.fact_counter.toString().length > 1 ? this.fact_counter.toString() : '0' + this.fact_counter.toString();
							this.fact_order.push({id: block.value['id'], prefixNum: block.value.prefixNum})
						}else{
							block.value['prefixNum'] = this.fact_order.find(o => o.id === block.value.id).prefixNum;
						}
					}
				})
				home_page.fact_cards = home_page.fact_cards.filter(block => block.value !== null);

				// Arrange interview pages
				home_page.interview_cards.forEach(block => {
					if(block.value){
						if(!this.interview_order.find(o => o.id === block.value.id)) {
							this.interview_order.push({id: block.value['id']})
						}
					}
				})
				home_page.interview_cards = home_page.interview_cards.filter(block => block.value !== null);

				// Arrange focus pages
				home_page.focus_cards.forEach(block => {
					if(block.value){
						if(!this.focus_order.find(o => o.id === block.value.id)) {
							this.focus_order.push({id: block.value['id']})
						}
					}
				})
				home_page.focus_cards = home_page.focus_cards.filter(block => block.value !== null);

				if(home_page.canonical_page){
					home_page.translations[window.default_language] = home_page.canonical_page[window.default_language]
				}
				// console.log(home_page)
				this.home_temp = home_page;
			},
			// The following link sets the home page object/ This is needed to run after pages are set, because it needs data on the interview pages
			setHomeTitles(){
				this.home_temp.interview_cards.forEach((block, index) => {
					let page = this.json_data.find(o => o.id === block.value.id)
					if (page){
						block.value.interviewed_position = page.interviewed_position;
						block.value.interviewed_surname = page.interviewed_surname;
						block.value.interviewed_name = page.interviewed_name;
					} else {
						this.home_temp.interview_cards.splice(index,1)
					}
				})
				this.home_temp.fact_cards.forEach((block, index) => {
					let page = this.json_data.find(o => o.id === block.value.id)
					if (page){
						block.value.link_title = page.link_title;
					} else {
						this.home_temp.fact_cards.splice(index,1)
					}
				})
				this.home_temp.focus_cards.forEach((block, index) => {
					let page = this.json_data.find(o => o.id === block.value.id)					
					if (page){
						block.value.link_title = page.link_title;
					} else {
						this.home_temp.focus_cards.splice(index,1)
					}
				})
				this.json_data.push(this.home_temp)
				this.test_preview()
			},
			// used on reload button in case of fail getting the site data
			reloadPage(){
				window.location.reload()
			}
		},
		created() {
			// When created and vars are set, make axios call to get data
			this.getData()
		}
	}
</script>