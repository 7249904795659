<template>
    <!-- <img v-if="logo" class="header-logo" :src="logo" /> -->
    <img v-if="logo" class="header-logo" v-lazy="logo.startsWith('http') ? logo : mediaURLPrefix+logo" :alt="logo_alt"/>
</template>

<script>
	export default {
		data: function () {
			return{
				mediaURLPrefix: window.mediaURLPrefix,
				logo: window.app_home_logo.url,
				logo_alt: window.app_home_logo.alt
			}
		},
    }
</script>