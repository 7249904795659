<template>

    <figure :class="{['text-'+alignment]: alignment}">
        <picture>
            <!-- <img :alt="image.title" :data-src="mediaURLPrefix+curr_image.url" :width="curr_image.width" :height="curr_image.height" :data-loading="mediaURLPrefix+image.renditions.small.url" /> -->
            <img :alt="alt" v-lazy="mediaURLPrefix+curr_image.url" :width="curr_image.width" :height="curr_image.height" />
        </picture>
        <figcaption v-if="caption" :class="{'grid-container': size == 'fullwidth'}">{{ caption }}</figcaption>
    </figure>

</template>

<script>
    export default {
        props: {
            image: {
                type: Object
            },
            size: {
                type: String
            },
            alignment: {
                type: String
            },
            caption: {
                type: String
            },
            alt: {
                type: String
            },
        },
        data: function () {
            return{
                mediaURLPrefix: window.mediaURLPrefix,
                curr_image: {}
            }
        },
        created(){
            if (window.matchMedia('(min-width: 1440px)').matches) {
                this.curr_image = this.image.renditions.large
            } else if (window.matchMedia('(min-width: 1024px)').matches) {
                this.curr_image = this.image.renditions.medium
            } else if (window.matchMedia('(min-width: 640px)').matches) {
                this.curr_image = this.image.renditions.tablet
            }else if (window.matchMedia('(min-width: 320px)').matches) {
                this.curr_image = this.image.renditions.small
            }
        }
    }
</script>