<template>
	<div class="page-blocks">
		<template v-for="block in body">
			<TextBlock v-if="block.type === 'text_block'" :value="block.value" v-bind:key="block.id" />
			<QuestionAnswer v-else-if="block.type === 'question_answer_block'" :value="block.value" v-bind:key="block.id" />
			<ImageBlock v-else-if="block.type === 'image_block'" :value="block.value" v-bind:key="block.id" />
			<TwoImagesBlock v-else-if="block.type === 'two_images_block'" :value="block.value" v-bind:key="block.id" />
			<Graphic v-else-if="block.type === 'graphic_block'" :value="block.value" v-bind:key="block.id" />
			<CallToAction v-else-if="block.type === 'call_to_action_block'" :value="block.value" v-bind:key="block.id" />
			<Video v-else-if="block.type === 'video_block'" :value="block.value" v-bind:key="block.id" />
			<Quote v-else-if="block.type === 'quote_block'" :value="block.value" v-bind:key="block.id" />
			<Box v-else-if="block.type === 'box_block'" :value="block.value" v-bind:key="block.id" />
			<BoxFacts v-else-if="block.type === 'box_facts_block'" :value="block.value" v-bind:key="block.id" />
			<InterviewTeaser v-else-if="block.type === 'interview_teaser_block'" :value="block.value" v-bind:key="block.id" />
			<SliderScroll v-else-if="block.type === 'image_slider_block'" :value="block.value" v-bind:key="block.id" />
			<Footnote v-else-if="block.type === 'footnote_block'" :value="block.value" v-bind:key="block.id" />
			<Accordion v-else-if="block.type === 'accordion_block'" :value="block.value" v-bind:key="block.id" :hash="$parent.$parent.$parent.hash" :reset="$parent.$parent.$parent.resetAccordion" />
			<TeaserBlock v-if="block.type === 'teaser_block'" :value="block.value" v-bind:key="block.id" />
			<ImageText v-if="block.type === 'image_text_block'" :value="block.value" v-bind:key="block.id" />
		</template>
	</div>
</template>

<script>
	// This component functions as a switch between blocks, it is used to display the blocks inside the
	// "body" StreamBlock of each page.

	import Box from './blocks/Box';
	import BoxFacts from './blocks/BoxFacts';
	import CallToAction from './blocks/CallToAction';
	import ImageBlock from './blocks/ImageBlock';
	import TwoImagesBlock from './blocks/TwoImagesBlock';
	import InterviewTeaser from './blocks/InterviewTeaser';
	import QuestionAnswer from './blocks/QuestionAnswer';
	import Quote from './blocks/Quote';
	import TextBlock from './blocks/TextBlock';
	import Video from './blocks/Video';
	import Graphic from './blocks/Graphic';
	import SliderScroll from './blocks/SliderScroll';
	import Footnote from './blocks/Footnote';
	import Accordion from './blocks/Accordion';
	import TeaserBlock from './blocks/TeaserBlock';
	import ImageText from './blocks/ImageText';

    export default {
        props: {
            body: {
                type: Array
			},
			data: {
				type: Array
			},
			pageid: {
				type: Number
			}
		},
		components: {
			Box,
			BoxFacts,
			CallToAction,
			ImageBlock,
			TwoImagesBlock,
			InterviewTeaser,
			QuestionAnswer,
			Quote,
			TextBlock,
			Video,
			Graphic,
			// Slider,
			SliderScroll,
			Footnote,
			Accordion,
			TeaserBlock,
			ImageText,
		}
    }
</script>