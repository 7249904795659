<template>
    <div v-on:click="navigate()">
        <h3 class="search-result-card-title">{{record.title}}</h3>
        <div class="search-result-card-body" v-html="record.lead_text"></div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        props: {
            record: {
                type: Object
            },
        },
        data: function () {
            return {

            }
        },
        watch: {

        },
        methods: {
            navigate() {
                this.$emit('close-modal')
                this.$parent.$parent.menuOpen = false;
                this.$parent.$parent.goTo({id: this.record.id, mode: 'swipe-left'})
            },
            async loadPage() {
                try {
                    let url = `pages/${this.record.id}/`

                    // Call server api
                    const res = await axios.get(window.api_url + `${url}`).catch(errors => {
                    });

                    let page = res.data

                    // Push Page to Store json_data
                    this.$parent.$parent.$parent.json_data.push(page)
                } catch (e) {
                    console.log(e)
                }
            }
        },
        created() {
            let findPage = this.$parent.$parent.$parent.json_data.find(o => o.id === this.record.id);
            if (!findPage) {
                this.loadPage()
            }
        }
    }
</script>