<template>
    <div class="module module-footnote">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a>         
        <div class="grid-container grid-x">
            <p class="foot-note cell small-12 medium-10 large-8" v-html="value.text" />
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Object
            }
        },
    }
</script>
