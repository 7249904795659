<template>
	<section class="app-footer">
		<div class="grid-container grid-x">
			<div class="cell small-12 medium-10 large-8">
				<div class="grid-x grid-margin-x">
					<div class="cell small-12">
						<SocialLinks :links="$parent.social_channels_data" />
					</div>
					<div class="cell small-12">{{value.logo_title}}</div>
					<div class="cell small-12">
						<a :href="value.logo_url" target="_blank">
							<svg width="195" height="20" viewBox="0 0 195 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M104.647 7.87316C110.356 8.29264 111.771 9.02672 111.771 13.5421C111.771 16.797 111.9 19.999 103.413 19.999C98.5012 19.999 95.2344 19.8951 95.2344 13.4382H100.173C100.173 15.4846 100.893 15.6954 103.414 15.6954C105.831 15.6954 106.629 15.5376 106.629 13.8846C106.629 12.1788 106.346 12.2047 103.929 12.0469L102.798 11.969C98.0383 11.6275 95.2618 11.6544 95.2618 6.01049C95.2618 0.368539 98.373 0 103.415 0C107.633 0 111.233 0.0789014 111.233 5.51211V6.3251H106.295C106.295 4.30462 105.472 4.30462 103.415 4.30462C100.638 4.30462 100.407 4.8819 100.407 5.98452C100.407 7.55955 101.152 7.61249 102.516 7.71636L104.647 7.87316Z" fill="white"/>
								<path d="M131.087 0.15625V13.2798C131.087 18.5293 128.669 19.9994 123.012 19.9994C115.297 19.9994 114.473 18.0039 114.473 13.2798V0.15625H119.617V13.174C119.617 15.4052 120.852 15.4312 123.012 15.4312C125.197 15.4312 125.943 15.2474 125.943 12.9383V0.15625H131.087Z" fill="white"/>
								<path d="M139.574 19.8416H134.43V0.15625H139.574V19.8416Z" fill="white"/>
								<path d="M151.682 7.87316C157.392 8.29264 158.806 9.02672 158.806 13.5421C158.806 16.797 158.934 19.999 150.447 19.999C145.535 19.999 142.27 19.8951 142.27 13.4382H147.208C147.208 15.4846 147.927 15.6954 150.448 15.6954C152.867 15.6954 153.663 15.5376 153.663 13.8846C153.663 12.1788 153.38 12.2047 150.963 12.0469L149.832 11.969C145.073 11.6275 142.296 11.6544 142.296 6.01049C142.296 0.368539 145.408 0 150.448 0C154.667 0 158.266 0.0789014 158.266 5.51211V6.3251H153.329C153.329 4.30462 152.506 4.30462 150.448 4.30462C147.671 4.30462 147.44 4.8819 147.44 5.98452C147.44 7.55955 148.187 7.61249 149.549 7.71636L151.682 7.87316Z" fill="white"/>
								<path d="M170.252 7.87316C175.962 8.29264 177.377 9.02672 177.377 13.5421C177.377 16.797 177.505 19.999 169.018 19.999C164.106 19.999 160.84 19.8951 160.84 13.4382H165.777C165.777 15.4846 166.497 15.6954 169.019 15.6954C171.437 15.6954 172.234 15.5376 172.234 13.8846C172.234 12.1788 171.95 12.2047 169.533 12.0469L168.402 11.969C163.644 11.6275 160.866 11.6544 160.866 6.01049C160.866 0.368539 163.978 0 169.019 0C173.238 0 176.836 0.0789014 176.836 5.51211V6.3251H171.899C171.899 4.30462 171.076 4.30462 169.019 4.30462C166.242 4.30462 166.011 4.8819 166.011 5.98452C166.011 7.55955 166.757 7.61249 168.118 7.71636L170.252 7.87316Z" fill="white"/>
								<path d="M185.245 8.05638H193.474V11.7048H185.245V15.536H194.194V19.8416H180.102V0.15625H194.015V4.46187H185.246V8.05638H185.245Z" fill="white"/>
								<path d="M0 0.15625H10.1332C13.9657 0.15625 16.2294 0.891331 16.2294 5.19595C16.2294 7.68884 15.7401 9.3158 13.1945 9.73728V9.94701C16.0493 10.1308 16.5377 11.8886 16.5377 14.3555C16.5377 19.1845 14.3259 19.8416 10.237 19.8416H0V0.15625ZM5.14392 8.03041H8.84723C10.647 8.03041 11.0845 7.7158 11.0845 6.14077C11.0845 4.95925 10.9044 4.5138 9.46478 4.46087H5.14392V8.03041ZM5.14392 15.537H9.02633C10.6979 15.537 11.3928 15.1425 11.3928 13.5425C11.3928 11.9156 10.8017 11.6788 9.13007 11.6788H5.14392V15.537Z" fill="white"/>
								<path d="M24.4047 8.05638H32.6343V11.7048H24.4047V15.536H33.3547V19.8416H19.2617V0.15625H33.1756V4.46187H24.4056V8.05638H24.4047Z" fill="white"/>
								<path d="M45.3392 19.8416H40.1962V4.72354H35.2578V0.15625H50.5349V4.72354H45.3392V19.8416Z" fill="white"/>
								<path d="M51.5625 12.782V7.21798C51.5625 1.15556 55.2658 0 60.487 0C65.7083 0 69.4116 1.15456 69.4116 7.21798V12.782C69.4116 18.8434 65.7083 20 60.487 20C55.2658 20 51.5625 18.8434 51.5625 12.782ZM64.113 12.2047V7.76829C64.113 4.85493 63.1618 4.56629 60.487 4.56629C57.8123 4.56629 56.9384 4.85493 56.8611 7.76829V12.2037C56.9384 15.1421 57.8123 15.4307 60.487 15.4307C63.1618 15.4307 64.113 15.1431 64.113 12.2047Z" fill="white"/>
								<path d="M77.1547 19.8416H72.0117V0.15625H80.7043L86.2338 15.2744H86.4394L86.1311 0.15625H91.274V19.8416H82.5041L77.0519 4.72354H76.8464L77.1547 19.8416Z" fill="white"/>
							</svg>
						</a>
					</div>
					<div class="cell small-12" v-html="text" />
					<ul class="cell small-12 grid-x grid-margin-x footer-links">
						<li class="footer-links-item" v-for="link in menu" v-bind:key="link.id">
							<a :id="link.id" :href="link.url" v-on:click.stop.prevent="click(link.id)" rel="nofollow">{{link.title}}</a>
						</li>
					</ul>
					<hr/>
					<div class="cell small-12 copyright-text">{{value.copyright}}</div>
				</div>
			</div>
		</div>
	</section>
</template>


<script>
import processAnchors from '../scripts/replaceInternalAnchors';
import SocialLinks from './snippets/SocialLinks'

    export default {
		props: {
			value: {
				type: Object
			},
		},
		components: {
			SocialLinks
		},
		data: function(){
			return{
				menu: [],
				pgettext: window.pgettext,
				text: "",
				logo_title: "",
				logo_url: ""
			}
		},
        methods: {
			// Function linked to Base's goTo to navigate inside the site
            click(page_id){
				this.$parent.goTo({id: page_id, mode: "link"})
            }
		},
		created(){
			// get link data for the footer section
			this.value.menu.forEach(link => {
				let page = this.$parent.json_data.find(o => o.id === link.id);
				link.title = page.title;
				this.menu.push(link);
			});
            this.logo_title = this.value.logo_title
            this.logo_url = this.value.logo_url
            this.text = processAnchors(this.value.text, this.$parent.json_data)
		}
    }
</script>