<template>
    <div class="module module-download cell" :class="[{'download-image small-12 medium-6 large-4': block.type === 'download_image_block'}, {'download-document small-12 medium-4 large-3': block.type === 'download_document_block'}]">
                
        <div class="grid-container">
            <p class="caption" v-html="block.value.text" />
            <PictureThumbnail v-if="$parent.$parent.$parent.$parent.$parent.lazyload.includes($parent.$parent.pageid) && block.value.thumbnail" :image="block.value.thumbnail" :alt="block.value.thumbnail.alt" />
            <p class="caption">{{block.value.format.toUpperCase()}}, {{size.toFixed(1)}} MB</p>
            <a class="caption" :href="block.value.download_url" target="_blank" rel="nofollow" download>
                <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="16.1" x2="14.313" y2="16.1" stroke="black" stroke-width="1.8"/>
                    <path d="M7.0752 0L7.0752 12.1446" stroke="black" stroke-width="1.8" stroke-miterlimit="10"/>
                    <path d="M2.26687 7.72097L7.0752 12.5293L11.8835 7.72097" stroke="black" stroke-width="1.8"/>
                </svg>
                <span>{{typeof pgettext === "function" && pgettext ? pgettext('Download image/document "Download" button', 'Download') : "Download"}}</span>
            </a>
        </div>

    </div>
</template>

<script>
    import PictureThumbnail from '../snippets/PictureThumbnail'

    export default {
        props: {
            block: {
                type: Object
            }
        },
		data: function () {
			return { 
                size: 0,
				pgettext: window.pgettext,
			}
		},
        components: {
            PictureThumbnail
        },
        created(){
            this.size = Math.round((this.block.value.size + Number.EPSILON) * 100) / 100000000
        }
    }
</script>