<template>
    <div class="module module-box-facts">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a> 
        <div class="grid-container grid-x">
            <div class="box-content cell small-12 medium-10 large-8">
                <div class="grid-x grid-margin-x">
                    <div class="cell small-12 medium-6">
                        <div v-html="value.text" />
                        <p class="caption">{{value.caption}}</p>
                    </div>
                    <div class="cell small-12 medium-6">
                        <div class="fact" v-for="fact in value.facts" v-bind:key="fact.number" >
                            <p class="fact-number h2">{{fact.value.number}}</p>
                            <p class="fact-text caption">{{fact.value.text}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Object
            }
        }
    }
</script>
