<template>
    <div class="module module-video">
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a>         
        <div class="video-container grid-container grid-x">
            <div class="cell small-12 medium-10 large-8">
                <div class="video">
                    <div class="video-wrapper aspect-ratio-16-9">
                        <iframe v-if="value.video_portrait_version" class="video-portrait-version"
                            :class="{'autoplay_in_view': value.autoplay || value.video_as_background}" width="100%"
                            :src="videoEmbedUrlPortrait"
                            frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe
                            :class="{'autoplay_in_view': value.autoplay || value.video_as_background}" width="100%"
                            :src="videoEmbedUrl"
                            frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="value.caption" class="grid-container grid-x caption">
            <p class="cell small-12 medium-10 large-8">{{ value.caption }}</p>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Object
            }
        },
        data: function(){
            return{
                column_classes: [],
                video_embed_url: "",
                portrait_video_embed_url: "",
            }
        },
        methods: {
            detectSource(url){
                let result = "";
                if(url.includes('youtube.com') || url.includes('youtu')) {
                    result = "https://www.youtube-nocookie.com/embed/" + (url.includes('?v=') ? url.split('?v=')[1] : url.split('/').reverse()[0]);
                } else if (url.includes('vimeo.com') || url.includes('vimeo')){
                    result = "https://player.vimeo.com/video/" + url.split('vimeo.com/')[1];
                }
                return result;
            }
        },
        computed: {
            videoEmbedUrl () {
                let processed_url = [this.detectSource(this.value.video_url), "?"];
                if(this.value.autoplay || this.value.video_as_background) processed_url.push("autoplay=0&amp;");
                if(this.value.video_as_background) processed_url.push("background=1&amp;controls=0&amp;");
                if(this.value.loop) processed_url.push("loop=1&amp;");
                if(this.value.muted || this.value.video_as_background == 'background-muted') processed_url.push("muted=1&amp;mute=1&amp;");
                if(!this.value.muted && this.video_as_background == 'background-unmuted') processed_url.push("muted=0&amp;");
                return processed_url.join("");
            },
            videoEmbedUrlPortrait () {
                let processed_url = [this.detectSource(this.value.video_portrait_version), "?"];
                if(this.value.autoplay || this.value.video_as_background) processed_url.push("autoplay=0&amp;");
                if(this.value.video_as_background) processed_url.push("background=1&amp;controls=0&amp;");
                if(this.value.loop) processed_url.push("loop=1&amp;");
                if(this.value.muted || this.value.video_as_background == 'background-muted') processed_url.push("muted=1&amp;mute=1&amp;");
                if(!this.value.muted && this.video_as_background == 'background-unmuted') processed_url.push("muted=0&amp;");
                return processed_url.join("");
            }
        },
    }
</script>