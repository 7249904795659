<template>
    <div v-if="value.image_mobile && value.image_large" :class="`module module-graphic ` + value.image_type + ` ` + (value.theme ? `themed-` + value.theme : ``)">  
        <a v-if="value.slug" :id='[value.slug]' class="module-anchor-link">&nbsp;</a> 
        <div class="grid-container grid-x">
            <div class="cell small-12 medium-10 large-8">
                <h4 class="subtitle-two" v-if="value.title">{{value.title}}</h4>
                <div class="subtitle" v-if="value.subtitle" v-html="value.subtitle" />
            </div>

            <div class="graphic-wrapper cell small-12 medium-10 large-8">
                <div :class="value.image_description ? `grid-margin-x grid-x` : `cell small-12 medium-10 large-8`">
                    <div :class="`cell small-12 medium-` + (value.image_description ? `6` : `12`) + ` large-` + (value.image_description ? `6` : `12`) ">
                        <img v-if="value.image_mobile" class="graphic-small" v-lazy="value.image_mobile.src.startsWith('http') ? value.image_mobile.src : mediaURLPrefix+value.image_mobile.src" :alt="value.image_alt" :width="value.image_mobile.width" :height="value.image_mobile.height"/>
                        <img v-if="value.image_large" class="graphic" v-lazy="value.image_large.src.startsWith('http') ? value.image_large.src : mediaURLPrefix+value.image_large.src" :alt="value.image_alt" :width="value.image_large.width" :height="value.image_large.height"/>
                    </div>
                    <div v-if="value.image_description" class="description cell small-12 medium-6 large-6" v-html="value.image_description" />
                </div>
            </div>

            <div class="cell small-12 medium-10 large-8 caption" v-if="value.caption" v-html="value.caption" />
        </div>
    </div>
</template>

<script>    
    export default {
        props: {
            value: {
                type: Object
            }
        },
        data: function () {
            return{
                mediaURLPrefix: window.mediaURLPrefix,
            }
        },

    }
</script>