<template>

    <figure v-if="image">
        <picture>
            <img :alt="image.alt ? image.alt : image.title" v-lazy="image.url.startsWith('http') ? image.url : mediaURLPrefix+image.url" :width="image.width" :height="image.height" />
        </picture>
    </figure>

</template>

<script>
    export default {
        props: {
            image: {
                type: Object
            },
        },
        data: function () {
            return{
                mediaURLPrefix: window.mediaURLPrefix,
            }
        },
    }
</script>