import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import Vue2TouchEvents from 'vue2-touch-events'
import VueLazyload from 'vue-lazyload'
import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue';

Vue.use(Vuelidate)
Vue.use(Vue2TouchEvents)
Vue.use(VueLazyload, {
  preLoad: .25,
  throttleWait: 50,
  observer: true
});
Vue.use(OverlayScrollbarsPlugin);

Sentry.init({
  Vue,
  dsn: (window.env.stage.toUpperCase() !== 'LOCAL' && window.env.sentry_dsn) ? window.env.sentry_dsn : null,
  // dsn: window.env.sentry_dsn,
  environment: window.env.stage,
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app-headless')
