<template>
	<div class="form">
		<div class="grid-container grid-x">
			<div class="cell small-12 medium-10 large-8">
				<h5>{{data.title}}</h5>

				<form v-on:submit.prevent="onSubmit" class="grid-x grid-margin-x" :class="['status-'+submitStatus]">

					<label class="cell small-12 medium-6" :class="{ 'form-group--error': $v.name.$error }">
						
						{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Form Name label', 'First name') : "First name"}} *

						<input type="text" name="name" ref="name" v-model.trim="$v.name.$model">
						<div class="error" v-if="!$v.name.required">{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Name input error (required)', 'Please fill in') : "Please fill in"}}</div>
					</label>

					<label class="cell small-12 medium-6" :class="{ 'form-group--error': $v.surname.$error }">
						{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Form Surname label', 'Surname') : "Surname"}} *
						
					
						<input type="text" name="surname" ref="surname" v-model.trim="$v.surname.$model">
						<div class="error" v-if="!$v.surname.required">{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Surname input error (required)', 'Please fill in') : "Please fill in"}}</div>
					</label>

					<label class="cell small-12 medium-6" :class="{ 'form-group--error': $v.email.$error }">
						
						{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Form Email label', 'E-mail') : "E-mail"}} *

						<input type="text" name="email" ref="email" v-model.trim="$v.email.$model">
						
						<div class="error">
							
							<span v-if="!$v.email.required">
								{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Email input error (required)', 'Please fill in') : "Please fill in"}}. 
							</span>
							
							<span v-if="!$v.email.email">
								{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Email input error (invalid)', 'This email address is invalid') : "This email address is invalid"}}. 
							</span>

						</div>
					</label>

					<label class="cell small-12 medium-6" :class="{ 'form-group--error': $v.phone.$error }">
						{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Form Phone label', 'Telephone') : "Telephone"}} *
						

						<input type="text" name="phone" ref="phone" v-model.trim="$v.phone.$model">
						<div class="error">
							<span v-if="!$v.phone.required">{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Phone input error (required)', 'Please fill in') : "Please fill in"}}. </span>
							

							<span v-if="!$v.phone.isPhoneNumber">{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Phone input error (isPhoneNumber)', 'Please enter a valid phone number') : "Please enter a valid phone number"}}. </span>
							

							<span v-if="!$v.phone.minLength && $v.phone.isPhoneNumber">
								{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Phone input error (length)', 'Please enter a valid phone number') : "Please enter a valid phone number"}}
							</span>
						</div>
					</label>

					<label class="cell small-12 form-message-textarea" :class="{ 'form-group--error': $v.message.$error }" >
						{{ typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Form Message label', 'Message') : "Message" }} *
						
						
						<textarea name="message" ref="message" v-model.trim="$v.message.$model"></textarea>
						<div class="error" v-if="!$v.message.required">{{typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Message input error (required)', 'Please fill in') : "Please fill in"}}</div>
					</label>
					<div class="cell form-help-text" v-html="data.help_text"/>

					<div class="cell small-12 text-center">
						<button class="hollow button button-form button-white" :disabled="submitStatus === 'pending'">
							
							{{(typeof pgettext === "function" && pgettext ? pgettext('Contact Form - Form Submit button label', 'Submit') : "Submit").toUpperCase()}}
						</button>
					</div>
		
				</form>

				<div v-if="submitStatus === 'failed' || submitStatus === 'error'" class="form-status-message message-error">
					<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="25" cy="25" r="23.5" fill="white" stroke="#2C2C2C" stroke-width="3"/>
						<path d="M35.0707 17.0707L35.1414 17L35.0707 16.9293L33.0707 14.9293L33 14.8586L32.9293 14.9293L25 22.8586L17.0707 14.9293L17 14.8586L16.9293 14.9293L14.9293 16.9293L14.8586 17L14.9293 17.0707L22.8586 25L14.9293 32.9293L14.8586 33L14.9293 33.0707L16.9293 35.0707L17 35.1414L17.0707 35.0707L25 27.1414L32.9293 35.0707L33 35.1414L33.0707 35.0707L35.0707 33.0707L35.1414 33L35.0707 32.9293L27.1414 25L35.0707 17.0707Z" fill="#2C2C2C" stroke="#2C2C2C" stroke-width="0.2"/>
					</svg>
					<div v-html="data.fail_message"/>
				</div>
				<div v-if="submitStatus === 'sent'" class="form-status-message message-success">
					<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="25" cy="25" r="23.5" fill="white" stroke="#2C2C2C" stroke-width="3"/>
						<path d="M15.9576 24.4529L15.7815 24.2768L15.6047 24.4523L13.8297 26.2148L13.6517 26.3916L13.8291 26.569L20.8166 33.5565L20.9934 33.7332L21.1701 33.5565L36.1701 18.5565L36.3469 18.3797L36.1701 18.2029L34.4076 16.4404L34.2309 16.2637L34.0542 16.4403L20.9934 29.4887L15.9576 24.4529Z" fill="#2C2C2C" stroke="#2C2C2C" stroke-width="0.5"/>
					</svg>
					<div v-html="data.success_message"/>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import { required, email, minLength } from 'vuelidate/lib/validators'

	const isPhoneNumber = (value) => /^[0-9 +]+$/.test(value);

	export default {
		props: {
			page: {
				type: Object
			},
			token: {
				type: String
			},
			data: {
				type: Object
			}
		},
		components: {
			//VueRecaptcha
		},
		data: function () {
			return { 
				response: null,
				loading: false,
				pgettext: window.pgettext,
				interpolate: window.interpolate,
				name: "",
				surname: "",
				email: "",
				phone: "",
				message: "",
				submitStatus: null,
				// recaptcha_key: window.recaptcha_key
			}
		},
		validations: {
			name: {
				required,
			},
			surname: {
				required,
			},
			email: {
				required,
				email
			},
			phone: {
				required,
				minLength: minLength(7),
				isPhoneNumber
			},
			message: {
				required,
			},
		},
		methods: {
			onSubmit(){
				// start vuelidate validation
				this.$v.$touch()
				if (this.$v.$invalid) {
					this.submitStatus = 'error'
				} else {
					this.submitStatus = 'pending'
					// axios call
					try{
						axios({
							method: 'POST',
							withCredentials: true,
							headers: {'X-CSRFToken': this.token},
							url: this.page.meta.html_url,
							data: {
								name: this.name,
								surname: this.surname,
								email: this.email,
								phone: this.phone,
								message: this.message
							}
						}).then(form_response => {
							this.response = form_response
							this.submitStatus = this.response.data.status;
							this.name = null
							this.surname = null
							this.email = null
							this.phone = null
							this.message = null
							this.$v.$reset();
						});
					} catch (error) {
						this.submitStatus = "failed"
					}
				}
			}
		}
	}
</script>