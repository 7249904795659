<template>
	<div>
		<!-- <ul v-if="show_lang_switcher && (on_drawer || active === $parent.$parent.home_id)" class="language-switcher" :class="{'show-on-tablet': showOnHome}"> -->
		<ul v-if="show_lang_switcher" class="language-switcher" :class="{'show-on-tablet': showOnHome}">
			<li v-for="lang in languages" v-bind:key="lang" :class="language === lang ? 'active' : null" >
				<span v-if="language === lang" v-on:click.prevent="" >{{lang}}</span>
				<a v-else :href="switchUrl(lang)" :tabindex="tabindex">{{lang}}</a>
			</li>
		</ul>
	</div>
</template>

<script>
    export default {
		props: {
			show_lang_switcher: {
				type: Boolean
			},
			on_drawer: {
				type: Boolean,
				default: false
			},
			language: {
				type: String
			},
			active: {
				type: Number
			},
			showOnHome: {
				type: Boolean
			},
			tabindex: {
				type: Number,
				default: 0
			}
		},
		data: function () {
			return{
				currentLang: "",
				languages: window.app_languages
			}
		},
		methods: {
			switchUrl(lang) {
				let page = this.$parent.$parent.json_data.find(o => o.id === this.active)
				return page && page.translations[lang] ? page.translations[lang] : '/'
			}
		},
    }
</script>