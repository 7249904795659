<template>
	<GenericPage v-if="page.meta.type === 'app_headless.AppGenericPage'" :page="page" />
	<HomePage v-else-if="page.meta.type === 'app_headless.AppHomePage'" :page="page" />
	<FocusPage v-else-if="page.meta.type === 'app_headless.AppFocusPage'" :page="page" />
	<FactPage v-else-if="page.meta.type === 'app_headless.AppFactPage'" :page="page" :prev="$parent.just_left_id" />
	<InterviewPage v-else-if="page.meta.type === 'app_headless.AppInterviewPage'" :page="page" :prev="$parent.just_left_id" />
	<ContactPage v-else-if="page.meta.type === 'app_headless.AppContactPage'" :token="$parent.$parent.csrftoken" :page="page" />
	<DownloadsPage v-else-if="page.meta.type === 'app_headless.AppDownloadsPage'" :page="page" />
	<FAQPage v-else-if="page.meta.type === 'app_headless.AppFAQPage'" :page="page" />
	<BlogIndexPage v-else-if="page.meta.type === 'app_blog.BlogIndexPage'" :page="page" />
	<BlogPost v-else-if="page.meta.type === 'app_blog.BlogPost'" :page="page" />
</template>

<script>
	// This component functions as a switch between pages

    import GenericPage from './pages/GenericPage'
    import ContactPage from './pages/ContactPage'
	import HomePage from './pages/HomePage'
	import FactPage from './pages/FactPage'
	import FocusPage from './pages/FocusPage'
	import DownloadsPage from './pages/DownloadsPage'
	import FAQPage from './pages/FAQPage'
	import InterviewPage from './pages/InterviewPage'
	import BlogIndexPage from './pages/BlogIndexPage'
	import BlogPost from './pages/BlogPost'

    export default {
        props: {
            page: {
                type: Object
			},
			goTo: {
				type: Function
			}
		},
		components: {
            GenericPage,
            HomePage,
			ContactPage,
			FactPage,
			DownloadsPage,
			FAQPage,
			InterviewPage,
			FocusPage,
			BlogIndexPage,
			BlogPost
		}
    }
</script>